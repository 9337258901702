import React, { useEffect } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useParams } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "../../components/Pagination";
import {
  useLazyGetSubAdminByIdQuery,
  useLazyPutUpdateSubAdminByIdQuery,
  useLazyPostSubAdminPageQuery,
  usePostAddSubAudminMutation,
  useDeleteSubAdminByIdMutation,
} from "../../services/subAdmin";
import { Loader, showError, showToast } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { usePostchangeSubAdminStatusMutation } from "../../services/subAdmin";
import WarnModal from "../../components/WarnModal";

const ManageSubAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [deleteById] = useDeleteSubAdminByIdMutation();
  const [open, setOpen] = useState(false);
  const [UserListing, setUserListing] = useState<any>([]);
  const { id: userId } = useParams();
  console.log(userId, "---userid");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [MainPage, { isLoading }] = useLazyPostSubAdminPageQuery();
  console.log("isLoading", isLoading);
  const [ById, setById] = useState<any>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [userStatus] = usePostchangeSubAdminStatusMutation();

  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const [profile_picture, setProfilePicture] = useState("");
  let totalPages = Math.ceil(totalCount / 10);

  const handleDelete = async (id: number) => {
    try {
      const response = await deleteById({ userId: id }).unwrap();
      if (response?.code === 200) {
        showToast("Sub-Admin deleted successfully");
        fetchUserListing();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  const updateSubAdminStatus = async (id: number, status: boolean) => {
    const body = {
      is_active: status,
    };

    const response = await userStatus({
      body,
      userId: id,
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = UserListing.map((x: any) => {
        if (x.id === id) {
          return {
            ...x,
            is_active: status,
          };
        }
        return x;
      });
      setUserListing(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const fetchUserListing = async () => {
    let body = {
      start: page,
      length: 10,
      search: debouncedSearchTerm,
    };
    try {
      // isLoading(true);
      const response = await MainPage(body).unwrap();

      console.log("lksskskwnwn", response);
      if (response.code === 200) {
        setUserListing(response?.data || []);
        console.log("-----subAdmin response", response?.data);
        setTotalCount(response?.total_count);
      } else {
        setUserListing([]);
        setTotalCount(0);
      }
    } catch (error: any) {
      console.error("Error fetching subAdmin data:", error);
    }
  };
  useEffect(() => {
    fetchUserListing();
  }, [page, debouncedSearchTerm]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> Manage Sub-Admin</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
                Add Sub-Admin
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {UserListing.map((row: any, i: any) => (
                  <TableRow key={i}>
                    <TableCell align="center">
                      {(page - 1) * 10 + i + 1}
                    </TableCell>
                    <TableCell>
                      {row.first_name + " " + row.last_name}
                    </TableCell>
                    <TableCell>{row.sub_role.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone_no}</TableCell>
                    <TableCell>
                      {/* <Switch {...label} defaultChecked size="small" /> */}
                      <Switch
                        {...label}
                        size="small"
                        checked={row?.is_active}
                        onChange={() =>
                          updateSubAdminStatus(row?.id, !row?.is_active)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(`/manage-subAdmin/edit/${row?.id}`, {
                              state: { tab: 1 },
                            })
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(row?.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {UserListing?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={UserListing}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Sub-Admin"
      />
    </MainContainer>
  );
};

export default ManageSubAdmin;
