import {
  Box,
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
 
 
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { usePostForgotPasswordMutation } from "../../services/auth";
import { Loader, showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

/////
 
 
import FormControlLabel from "@mui/material/FormControlLabel";
 

 
 
////
const ForgotPassword = () => {
  const [forgotPasswordMutation, { isLoading }] =usePostForgotPasswordMutation();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();


const formik = useFormik({
  initialValues: {
    email: "",
  },
  validationSchema: Yup.object({
    email: Yup.string()
      .required("Email is required!")
      .matches(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        "Enter a valid email address!"
      ),
  }),
  onSubmit: async ( ) => {
    formik.setSubmitting(true);
    const data = {
      email: formik.values.email,
      
    };
    try {
     
      
      const response = await forgotPasswordMutation(data).unwrap();
      console.log(response);
      if (response?.statusCode === 200|| response?.code===200) {
        // const data = {
        //   email: formik.values.email,
        // };
        navigate("/verifyotp", { state: data });
        showToast("Otp sent successfully");
      }
    
  }catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
    
  },
});

const handleClickShowPassword = () => {
  setShowPassword(!showPassword);
};

const handleMouseDownPassword = (event: any) => {
  event.preventDefault();
};

 
  //
  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
       <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>

        <div className="auth_box">

      
          {/* email id input */}
          <form onSubmit={formik.handleSubmit}>

            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
  hiddenLabel
  placeholder="Email Address"
  className="text_field"
  fullWidth
  name="email"
  type="email"
  variant="outlined"
  onChange={formik.handleChange} // Ensure formik.handleChange is bound to onChange
  onBlur={formik.handleBlur}
  value={formik.values.email} // Make sure formik values are correctly bound to value prop
  helperText={formik.touched.email && formik.errors.email}
/>

            </FormControl>

            {/* //verify otp navigation */}
            <Box sx={{ pt: 3 }}>
            <Button
  className="btn btn_primary"
  color="primary"
  fullWidth
  size="large"
  type="submit" // Ensure button type is "submit"
  variant="contained"
>
  Send
</Button>

            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
