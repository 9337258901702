import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  useLazyGetvendorByIdQuery,
  usePostAdminAddVendorsMutation,
  useUpdateVendorMutation,
} from "../../services/manageVendors";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import { Loader, showError, showToast } from "../../constants";
import { usePostGetAllCategoriesMutation } from "../../services/categories";
import { Category } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";
import useAuth from "../../hooks/useAuth";

const VendorForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [getVendorByID] = useLazyGetvendorByIdQuery();
  const [category, setCategory] = useState<string>("");
  const [addVendor, { isLoading }] = usePostAdminAddVendorsMutation();
  // const [error, setError] = useState(false);
  const { id } = useParams();
  const [updateVendorById] = useUpdateVendorMutation();
  const [allCategory] = usePostGetAllCategoriesMutation();

  const [categoryData, setCategoryData] = useState<any>([]);

  const [vendor_category, setvendor_category] = useState("");
  console.log("ven", vendor_category);
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  console.log(ByIdDetails, "1232");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [getvendorById] = useLazyGetvendorByIdQuery();
  const [UpdatedState, setUpdatedState] = useState<any>([]);

  const [updateVendor, setupdateVendor] = useState<any>([]);
  //profile
  const [profile_picture, setprofile_picture] = useState("");
  const [profile_picture_Id, setprofile_picture_Id] = useState(null);
  console.log(profile_picture, "qoqikqww");
  //front

  const [document_back_image, setdocument_back_image] = useState("");
  const [document_back_image_Id, setdocument_back_image_Id] = useState(null);
  //back
  const [document_front_image, setdocument_front_image] = useState("");
  const [document_front_image_Id, setdocument_front_image_Id] = useState(null);
  // const[expiry_date,setexpiry_date]=useState("");
  const userData = useAuth();

  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true,
  });

  console.log("hide", hidePermission);

  const permissions = userData?.role?.permissions;
  console.log("uhshqbsqy", permissions);
  const permissions_name: string[] = [];

  if (permissions && permissions.length > 0) {
    permissions.forEach((permission: any) => {
      const moduleName = permission.module.name;
      permissions_name.push(moduleName);
    });
  }

  console.log(permissions_name, "permissions_name2");

  const checkPermission = () => {
    if (permissions_name?.length) {
      let idx = permissions_name.findIndex(
        (moduleName: string) => moduleName === "Manage vendors"
      );

      console.log("Index2897:", idx);

      if (idx !== -1) {
        const manageVendorsPermission = permissions[idx];
        console.log("02993jhehds", manageVendorsPermission);
        if (manageVendorsPermission && !manageVendorsPermission?.is_add_edit) {
          showToast("You don't have the permission to add on this page");
          navigate("/dashboard", { replace: true });
        } else {
          let data = {
            is_add_edit: permissions[idx]?.is_add_edit,
            is_delete: permissions[idx]?.is_delete,
          };
          setHidePermission(data);
          console.log("lsksjdisjs", hidePermission);
        }
      }
    } else {
      navigate("/dashboard", { replace: true });
      showError("You don't have permission for this activity");
    }
  };

  useEffect(() => {
    if (userData && userData?.role?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);
  console.log("jnsew2q22", document_front_image);
  const GetAllCategories = async () => {
    let body = {
      length: 10,
      start: 1,
      search: "",
    };
    try {
      const response = await allCategory(body).unwrap();
      if (response?.code === 200) {
        setCategoryData(response?.data || []);
      } else {
        setCategoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      profile_picture: profile_picture,
      document_front_image: document_front_image,
      document_back_image: document_back_image,
      document_number: null,
      expiry_date: "",
      vendor_category: vendor_category,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone_no: Yup.string().required("Phone number is required"),

      document_number: Yup.number().required("Document number is required"),
      expiry_date: Yup.string().required("Expiry date is required"),
      vendor_category: Yup.mixed().required("Vendor category is required"),
    }),
    onSubmit: async (values) => {
      console.log("Ftug676:", values);
      let body = {
        first_name: values.first_name,
        last_name: values.last_name,
        phone_no: values.phone_no,
        email: values.email,
        profile_picture: profile_picture_Id,
        vendor_category: Number(vendor_category),
        documents: [
          {
            id: 0,
            front_side: document_front_image_Id,
            back_side: document_back_image_Id,
            document_number: Number(values.document_number),
            expiry_date: values.expiry_date,
          },
        ],
      };

      console.log("---body82737", body);
      console.log("Sub", body);
      if (id) {
        try {
          const response = await updateVendorById({
            id: id,
            body,
          }).unwrap();
          if (response?.code === 200) {
            showToast("Vendor updated successfully");
            navigate("/manage-vendors");
            setupdateVendor(response?.data);
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addVendor(body).unwrap();

          console.log("----vendor response", response);
          if (response?.code === 201) {
            showToast("Vendor added successfully");
            navigate("/manage-vendors");
            setUpdatedState(response);
          } else {
            showError("An error occurred");
          }
        } catch (error: any) {
          showError(error.message || "An error occurred");
        }
      }
    },
  });
  console.log("99434", vendor_category);
  const handleVendorCategory = (event: any) => {
    const selectedCategory = event.target.value;
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      const res = await UploadMedia(file);
      if (res?.code === 200) {
        formik.setFieldValue("profile_picture", res?.data[0]?.media_file_url);
        setprofile_picture(res?.data[0]?.media_file_url);
        setprofile_picture_Id(res?.data[0]?.id);
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showError("Error uploading image. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleFrontImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      const res = await UploadMedia(file);
      if (res?.code === 200) {
        formik.setFieldValue(
          "document_front_image",
          res?.data[0]?.media_file_url
        );
        setdocument_front_image(res?.data[0]?.media_file_url);
        setdocument_front_image_Id(res?.data[0]?.id);
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showError("Error uploading image. Please try again.");
    }
  };

  const handleBackImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      const res = await UploadMedia(file);
      if (res?.code === 200) {
        formik.setFieldValue(
          "document_back_image",
          res?.data[0]?.media_file_url
        );
        setdocument_back_image(res?.data[0]?.media_file_url);
        setdocument_back_image_Id(res?.data[0]?.id);
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showError("Error uploading image. Please try again.");
    }
  };

  const fetchDataById = async (id: any) => {
    if (id)
      try {
        const response = await getvendorById({ userId: id }).unwrap();
        console.log("9091", response);
        if (response.code === 200) {
          setByIdDetails(response?.data);
        }
      } catch (error) {
        console.error(error);
      }
  };
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setvendor_category(event.target.value);
  };
  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, [id]);
  useEffect(() => {
    GetAllCategories();
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Vendors</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-vendors");
              }}
            >
              Back
            </Button>
          </div>

          <Card className="cards">
            <form
              onSubmit={(event) => {
                formik.handleSubmit(event);
              }}
            >
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {profile_picture ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={profile_picture}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={(e) => {
                            setprofile_picture("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{ accept: "image/png,image/jpeg" }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e)}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        First Name
                      </Typography>
                      <TextField
                        hiddenLabel
                        type="text"
                        name="first_name"
                        className="text_field"
                        variant="outlined"
                        fullWidth
                        placeholder="First Name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.first_name && formik.errors.first_name
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Last Name
                      </Typography>
                      <TextField
                        hiddenLabel
                        type="text"
                        className="text_field"
                        name="last_name"
                        variant="outlined"
                        fullWidth
                        placeholder="Last Name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.last_name && formik.errors.last_name
                        }
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">Email</Typography>
                      <TextField
                        hiddenLabel
                        type="email"
                        name="email"
                        variant="outlined"
                        className="text_field"
                        fullWidth
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Phone Number
                      </Typography>
                      <PhoneInput
                        country="us"
                        placeholder="0 (000) 000-000"
                        enableSearch={true}
                        inputStyle={{ width: "100%" }}
                        value={formik.values.phone_no}
                        onChange={(value) =>
                          formik.setFieldValue("phone_no", value)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.phone_no && formik.errors.phone_no ? (
                        <div className="err_msg">{formik.errors.phone_no}</div>
                      ) : null}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Select Category
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          fullWidth
                          className="select_div"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={vendor_category}
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          onChange={(event) => {
                            handleCategoryChange(event);
                            formik.setFieldValue(
                              "vendor_category",
                              event.target.value
                            );
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {categoryData?.length > 0
                            ? categoryData?.map((row: any) => (
                                <MenuItem key={row?.id} value={row?.id}>
                                  {row?.category_name}
                                </MenuItem>
                              ))
                            : " "}
                        </Select>
                      </FormControl>
                      {vendor_category === "" &&
                      formik.touched.vendor_category &&
                      formik.errors.vendor_category ? (
                        <div className="err_msg">
                          {formik.errors.vendor_category}
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ p: 0 }}>
                    <Typography fontSize={18} sx={{ fontWeight: "bold" }}>
                      Upload Documents
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ p: 0 }}>
                    <Typography className="custom_label">
                      Front Image
                    </Typography>

                    <div className="upload_document w_50">
                      {document_front_image ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CancelIcon
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setdocument_front_image("");
                              }}
                            />
                          </div>
                          <figure>
                            <img src={document_front_image} alt="" />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-front">
                          <div className="inner">
                            <span>
                              <AddIcon />
                            </span>
                            <Input
                              id="icon-button-file-front"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleFrontImageUpload(e)}
                            />
                          </div>
                          {error && !document_front_image ? (
                            <h6 className="err_msg">Front image is required</h6>
                          ) : null}
                          {!document_front_image && (
                            <span>
                              <img src="static/images/ad_icon.png" alt="" />
                            </span>
                          )}
                        </label>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">Back Image</Typography>
                    <div className="upload_document w_50">
                      {document_back_image ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CancelIcon
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setdocument_front_image("");
                              }}
                            />
                          </div>
                          <figure>
                            <img src={document_back_image} alt="" />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-front">
                          <div className="inner">
                            <span>
                              <AddIcon />
                            </span>
                            <Input
                              id="icon-button-file-front"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleBackImageUpload(e)}
                            />
                          </div>
                          {!document_front_image && (
                            <span>
                              <img src="static/images/ad_icon.png" alt="" />
                            </span>
                          )}
                        </label>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}></Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Document Number
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="document_number"
                      variant="outlined"
                      fullWidth
                      placeholder="Doc Number"
                      value={formik.values.document_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.document_number &&
                        formik.errors.document_number
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Expiry Date
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"date"}
                      name="expiry_date"
                      variant="outlined"
                      fullWidth
                      placeholder="Expiry Date"
                      value={formik.values.expiry_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.expiry_date && formik.errors.expiry_date
                      }
                    />
                  </Grid>
                </Grid>

                {/* Document Number */}

                {/* Form Button */}
                <Grid item xs={12} sx={{ p: 0 }}>
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                      onClick={() => setError(true)}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default VendorForm;
