import { END_POINTS } from "../constants/url";
import { Category, manage_User } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  total_count: number;
  message: string;
  code: number;
};

type GetCategoryBody = {
  length: number;
  start: number;
  search: string;
};

type PutBlockUserParams = {
  categoryId: number;
  body: {
    is_active: boolean;
  };
};

type PutUpdateParams = {
  categoryId: number | string;
  body: {
    category_name: string;
    category_image: number | null;
  };
};

type AddCategoryBody = {
  category_name: string;
  category_image: number | null;
};

export const CategoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postGetAllCategories: builder.mutation<
      CommonResponseType & { data: Category[] },
      GetCategoryBody
    >({
      query: (body) => ({
        url: `${END_POINTS.getAllCategory}`,
        method: "POST",
        body,
      }),
    }),

    deleteCategory: builder.mutation<
      CommonResponseType,
      { categoryId: number }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.deleteCategory}${categoryId}/`,
        method: "DELETE",
      }),
    }),
    updateCategoryStatus: builder.mutation<
      { message: string; code: number },
      PutBlockUserParams
    >({
      query: ({ body, categoryId }) => ({
        url: `${END_POINTS.updateCategoryById}${categoryId}/`,
        method: "PUT",
        body,
      }),
    }),

    addCategory: builder.mutation<
      { message: string; code: number; data: any },
      AddCategoryBody
    >({
      query: (body) => ({
        url: END_POINTS.add_category,
        method: "POST",
        body,
      }),
    }),
    updateCategory: builder.mutation<
      { message: string; code: number },
      PutUpdateParams
    >({
      query: ({ body, categoryId }) => ({
        url: `${END_POINTS.updateCategoryById}${categoryId}/`,
        method: "PUT",
        body,
      }),
    }),

    getCategoryById: builder.query<
      CommonResponseType & { data: any },
      { categoryId: string | undefined }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.getCategoryById}/${categoryId}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostGetAllCategoriesMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryStatusMutation,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useLazyGetCategoryByIdQuery,
} = CategoryApi;
