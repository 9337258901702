import { Modal,Box } from "@mui/material";

const Loader = (props: { isLoad: boolean }) => {
  const style = {
    outline: "none",
  };

  return (
    <>
      {props.isLoad && (
        <Modal open>
          <Box className="loader_loading" sx={style}>
            <figure>
              <img
                src="/static/images/loader.gif"
                alt="loading"
                width={60}
                height={60}
              />
            </figure>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Loader;
