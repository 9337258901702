export const API_URL = "https://apidevnarwhale.appgrowthcompany.com/api/"; //development

// export const API_URL = "https://apistagingnarwhale.appgrowthcompany.com/api/"; //staging

 
let user_Id=26;
export const END_POINTS = {
 
  login: "admin-login/",
  forgot_password:"admin/forgot-password/",
  otp:"admin/forgot-password-verify-otp/",
  reset_password:"admin/update-password/",
  getAdmin:"admin/",
  upload:"upload/media/",
  change_password:"admin/change-password/",
  ////users
  manage_user:`users/`,
  pending_users:"pending-users/",
  approved_users:"approved-users/",
  rejected_users:"rejected-users/",
  verification_status:"verification-status/",
  active_status:"active-status/",
  delete_user:"delete-user/",
   DashBoardData:"get/dashboard-data/",
 //vendors
   all_vendors: "admin/get-all-vendors/",
  admin_add_vendors: "admin/add-vendors/",
  get_vendor_by_id:"admin/get-vendor-details-by-id/",
  update_vendor:"admin/update-vendors-by-id",
  delete_vendor:"admin/delete-vendors-by-id",
  userById: `user/`,

  ///categories
  add_category: "admin/add-category/",
  getAllCategory: "admin/get-all-category/",
  updateCategoryById: "admin/update-category-by-id/",
  deleteCategory: "admin/delete-category-by-id/",
  getCategoryById: "admin/get-category-by-id",
  changeRequest: "verification-status/",

  //report 
  TotalUsersGraph:"get/all-user-graph-data/",
  AllEarningGraph:"get/all-earning-graph-data/",
  TotalVendors:"get/all-vendor-graph-data/",

  //settings
  get_commision:"get/comission-fee/",
  add_update_commision_fee:"update/comission-fee/",

  //Sub Admin
  getSubAdminById:"get/sub-admin-by-id/", //GET
  UpdateSubAdminById:"update/sub-admin-by-id/",//PUT
  SubAdminListing:"get/all-sub-role/ ",
  DeleteSubAdminById:"delete/sub-admin-by-id/",//DELETE
  AddSubAdmin:"add/sub-admin/",
  SubAdminMainPage:"get/all-sub-admin/",
  module:"get/all-admin-module/",
  updateSubAdminById:"update/sub-admin-by-id/",
  //subAdmin roles
  SubAdminCreateRoll:"sub-admin/create-role/",
  deleteRoleSubAdmin:"delete/sub-role-by-id/",
///aml
add_update_amls:"update/amls/",
 get_amls:"get/amls/",

 //faq
 add_faqs: "add/faqs/",
  faq_by_id: "get/faqs-by-id",
  all_faqs: "get/all-faqs/",
  update_faq: "update/faqs-by-id",
  delete_faq: "delete/faqs-by-id",

  //cms
  add_cms: "add/cms/",
  cms_by_id: "get/cms-by-id/1/",
  all_cms: "get/all-cms/",
  update_cms: "update/cms-by-id/1/",
  delete_cms: "delete/cms-by-id/1/",
};
