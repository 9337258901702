import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import DeleteIcon from "@mui/icons-material/Delete";

import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";

const ManageTransactions = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [receiver, setReceiver] = useState<string>("");
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  const handleStateDate = (event: any) => {
    setStartDate(event.target.value as string);
  };

  const handleEndDate = (event: any) => {
    setEndDate(event.target.value as string);
  };
  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }

  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "a@yopmail.com",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Transactions</h1>
        </div>
        <Card sx={{ mt: 4 }} className="cards">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ pt: 2.3, display: "flex", gap: 2 }}>
            <SearchBar value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }} />

              <FormControl sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={receiver}
                  // multiple
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleReceiverChange}
                >
                  <MenuItem value="" disabled>
                    Select Vendor
                  </MenuItem>
                  <MenuItem value="All">Vendor 1</MenuItem>
                  <MenuItem value="User 1">Vendor 2</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="cards_header_right">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">Start Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="validFrom"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder={"Start Date"}
                  onChange={(val) => handleStateDate(val)}
                  value={startDate}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">End Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="validTill"
                  className="text_field"
                  variant="outlined"
                  disabled={!startDate}
                  fullWidth
                  placeholder={"End Date"}
                  onChange={(val) => handleEndDate(val)}
                  value={endDate}
                  inputProps={{
                    min: startDate,
                  }}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            </Box>
          </Box>
          <Box className="custom_tabs">
            <TableContainer className="table_container">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>

                    <TableCell align="center">Sender name</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Payment Amount</TableCell>
                    <TableCell align="center">Receiver Name</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Actions</TableCell>

                    {/* <TableCell align="center">Actions</TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{i + 1}</TableCell>

                      <TableCell align="center">{row.name}</TableCell>

                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">$100</TableCell>
                      <TableCell align="center">John</TableCell>
                      <TableCell align="center">25/12/2024</TableCell>
                      <TableCell align="center">Pending</TableCell>

                      {/* <TableCell>
                  <Switch {...label} defaultChecked size="small" />
                </TableCell> */}
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() => navigate("/manage-revenue/details")}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {/* <IconButton
                      onClick={() => navigate("/manage-service-provider/edit")}
                    >
                      <ModeEditIcon />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon />
                    </IconButton> */}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageTransactions;
