import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import Category from "@mui/icons-material/Category";
import Money from "@mui/icons-material/Money";
import Notifications from "@mui/icons-material/NotificationAdd";
import Report from "@mui/icons-material/Report";
import Support from "@mui/icons-material/Support";
import SubAdmin from "@mui/icons-material/AdminPanelSettings";
import Faqs from "@mui/icons-material/QuestionAnswer";
import Settings from "@mui/icons-material/Settings";
import Amls from "@mui/icons-material/AlarmSharp";
import Cms from "@mui/icons-material/CallMadeSharp";
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
  showError,
  showToast,
  Loader,
  STORAGE_KEYS,
} from "../../constants";
import { useAppDispatch } from "../../hooks/store";
import { usePostLoginMutation } from "../../services/auth";
import { setCredentials } from "../../reducers/authSlice";
 

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  //

  const items = [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      name: "Manage Users",
      icon: <PersonAddIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      name: "Manage Vendors",
      icon: <PeopleIcon />,
      key: "3",
      selected: "/manage-vendors",
    },
    {
      name: "Manage Categories",
      icon: <Category />,
      key: "4",

      selected: "/manage-categories",
    },
    {
      name: "Manage Transactions",
      icon: <Money />,
      key: "5",
      selected: "/manage-subscription",
    },
    {
      name: "Revenue Management",
      icon: <Money />,
      key: "6",

      selected: "/manage-payments",
    },
    {
      name: "Manage Notifications",
      icon: <Notifications />,
      key: "7",
      selected: "/manage-properties",
    },
    {
      name: "Report and Analysis",
      icon: <Report />,
      key: "8",

      selected: "/analytics",
    },

    {
      name: "Manage Customer Support",
      icon: <Support />,
      key: "9",
      selected: "/manage-tutorials",
    },
    {
      name: "Manage CMS",
      icon: <Cms />,
      key: "10",

      selected: "/cms",
    },
    {
      name: "Manage FAQs",
      icon: <Faqs />,
      key: "11",
      selected: "/faq",
    },

    {
      name: "Manage Sub-Admin",
      icon: <SubAdmin />,
      key: "12",

      selected: "/manage-subAdmin",
    },
    {
      name: "Mnage AMLs",
      icon: <Amls />,
      key: "13",
      selected: "/cms",
    },
    {
      name: "settings",
      icon: <Settings />,
      key: "14",
    },
  ];

  //
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string().required("Password is required"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        email: values.email,
        password: values.password,
      };

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.code === 200) {
          showToast("Login Successfully.");
          setToStorage(STORAGE_KEYS.token, response?.data?.access_token || "");
          dispatch(
            setCredentials({
              user: response?.data?.data || null,
              token: response?.data?.access_token || null,
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          if (response?.data?.role === "SUBADMIN") {
            const permission = response?.data?.permissions;

            if (response?.data?.role === "SUBADMIN" && permission?.length > 0) {
              const firstPermissionLabel = permission[0]?.name;
              const redirectPath = items.find(
                (item) => item.name === firstPermissionLabel
              )?.selected;

              if (redirectPath) {
                navigate(redirectPath);
              } else {
                console.error("Invalid permission label for redirection");
                // Handle the case where the label doesn't match any item in the array
              }
            }
          } else {
            // Default redirect for other roles or when no permissions are available
            navigate("/dashboard");
          }
          navigate("/dashboard", { replace: true });
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  // const handleLogout = () => {
  //   removeFromStorage(STORAGE_KEYS.token);
  //   removeFromStorage(STORAGE_KEYS.credentials);
  //   clearFormValues(); // Clear form values unconditionally
  //   navigate("/login", { replace: true });
  // };

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>

        <div className="auth_box bg_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1d1d1d" }}>
            <Typography variant="h5">Log in to Narwhale Admin</Typography>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%", zIndex: 1 }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                name="email"
                type="email"
                variant="outlined"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>

            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                className="text_field"
                name="password"
                hiddenLabel
                placeholder="Password"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label={"Remember me"}
                checked={formik.values.remember}
                name="remember"
                onChange={formik.handleChange}
              />

              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>Forgot Password?</Typography>
              </Box>
            </Box>

            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                type="submit"
              >
                Sign In
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
