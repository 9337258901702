import { END_POINTS } from "../constants/url";
import { Amls } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
    message: string,
    code: number
}

type addUpdateAmlsBody = {
    transaction_limit: number,
    transaction_amount_limit: number
}



export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getAmls: builder.query<
        CommonResponseType & {data: Amls},{}>({
            query: () => ({
                url: END_POINTS.get_amls,
                method: "GET",
            }),
        }),
        addUpdateAmls: builder.mutation<
        CommonResponseType & {data: Amls},
        addUpdateAmlsBody
        >({
            query: (body) => ({
                url: END_POINTS.add_update_amls,
                method: "PUT",
                body
            }),
        }),
    })
})


export const {
    useLazyGetAmlsQuery,
    useAddUpdateAmlsMutation

} = authApi;