import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";

const PurchaseHistory = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const handleStateDate = (event: any) => {
    setStartDate(event.target.value as string);
  };

  const handleEndDate = (event: any) => {
    setEndDate(event.target.value as string);
  };
  return (
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ pt: 2.3 }}>
          <SearchBar value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }} />
          </Box>
          <Box className="cards_header_right">
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">Start Date</Typography>
              <TextField
                hiddenLabel
                type={"date"}
                name="validFrom"
                className="text_field"
                variant="outlined"
                fullWidth
                placeholder={"Start Date"}
                onChange={(val) => handleStateDate(val)}
                value={startDate}
                onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography className="custom_label">End Date</Typography>
              <TextField
                hiddenLabel
                type={"date"}
                name="validTill"
                className="text_field"
                variant="outlined"
                disabled={!startDate}
                fullWidth
                placeholder={"End Date"}
                onChange={(val) => handleEndDate(val)}
                value={endDate}
                inputProps={{
                  min: startDate,
                }}
                onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
          </Box>
        </Box>
        <Box sx={{ pt: 2, display: "flex", gap: 2 }}>
          <Typography className="custom_label">
            Total Transaction Amount : $ 20,000
          </Typography>
        </Box>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell align="center">Transaction ID</TableCell>
                <TableCell align="center">Sender</TableCell>
                <TableCell align="center">Sender Email</TableCell>
                <TableCell align="center">Receiver</TableCell>
                <TableCell align="center">Receiver Email</TableCell>

                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{1}</TableCell>
                <TableCell>100003</TableCell>
                <TableCell align="center">John</TableCell>
                <TableCell align="center">John@yopmail.com</TableCell>
                <TableCell align="center">abdul</TableCell>
                <TableCell align="center">abdul@yopmail.com</TableCell>
                <TableCell>22/12/2023</TableCell>
                <TableCell>$100</TableCell>
                <TableCell>Received</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell align="center">{1}</TableCell>
                <TableCell>100003</TableCell>
                <TableCell align="center">abdul</TableCell>
                <TableCell align="center">abdul@yopmail.com</TableCell>
                <TableCell align="center">John</TableCell>
                <TableCell align="center">John@yopmail.com</TableCell>
                <TableCell>22/12/2023</TableCell>
                <TableCell>$100</TableCell>
                <TableCell>Sent</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default PurchaseHistory;
