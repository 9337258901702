import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import SubCategories from "./manageCategories/details";
import ChangePassword from "./auth/changePassword";
 
import ManageTransactions from "./manageReviews";
import ReviewDetails from "./manageReviews/details";
import ManageRevenue from "./revenueManagement";
import ManageQuestionnaire from "./manageQuestionnaire";
import AddQuestionnaire from "./manageQuestionnaire/add";
import QueryDetails from "./customerSupport/details";
import CommunityDetails from "./manageReviews/details";
import Settings from "./settings";
import RevenueDetails from "./revenueManagement/details";
import ManageAMLs from "./manageAMLs";
import TransactionDetails from "./manageReviews/details";
import ManageVendors from "./manageVendors";
import VendorForm from "./manageVendors/form";
import VendorDetails from "./manageVendors/details";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  SubCategories,
  ChangePassword,
 
  ManageTransactions,
  ReviewDetails,
  ManageRevenue,
  Settings,
  ManageQuestionnaire,
  AddQuestionnaire,
  QueryDetails,
  CommunityDetails,
  RevenueDetails,
  ManageAMLs,
  TransactionDetails,
  ManageVendors,
  VendorForm,
  VendorDetails,
};
