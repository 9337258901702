import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { Card, Box, Typography, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Commision, Payments, ServiceProviderEarnings } from "../../features";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageRevenue = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const handleStateDate = (event: any) => {
    setStartDate(event.target.value as string);
  };

  const handleEndDate = (event: any) => {
    setEndDate(event.target.value as string);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Revenue Management</h1>
        </div>
        <Card className="cards">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ pt: 2.3 }}>
            <SearchBar value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }} />

            </Box>
            <Box className="cards_header_right">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">Start Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="validFrom"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder={"Start Date"}
                  onChange={(val) => handleStateDate(val)}
                  value={startDate}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">End Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="validTill"
                  className="text_field"
                  variant="outlined"
                  disabled={!startDate}
                  fullWidth
                  placeholder={"End Date"}
                  onChange={(val) => handleEndDate(val)}
                  value={endDate}
                  inputProps={{
                    min: startDate,
                  }}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            </Box>
          </Box>
          <Box sx={{ pt: 2, display: "flex", gap: 2 }}>
            <Typography className="custom_label">
              Total Earning : $ 20,000
            </Typography>
            <Typography className="custom_label">
              Total Amount : $ 20,000
            </Typography>
          </Box>
          <Box className="custom_tabs">
            {/* <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Manage Payment" {...a11yProps(0)} />
                <Tab
                  label="Manage Service Provider Earnings"
                  {...a11yProps(1)}
                />
                <Tab label="Manage Commision" {...a11yProps(2)} />
              </Tabs>
            </Box> */}
            {/* <CustomTabPanel value={value} index={0}> */}
            <Payments />
            {/* </CustomTabPanel> */}
            {/* <CustomTabPanel value={value} index={1}>
              <ServiceProviderEarnings />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Commision />
            </CustomTabPanel> */}
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageRevenue;
