import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { showError, showToast } from "../constants";
import { useAddFaqMutation ,  useLazyDeleteFaqQuery, useLazyGetAllFaqQuery,} from "../services/manageFaq";
import useAuth from "../hooks/useAuth";

const MainFaq = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [getAllFaqs] = useLazyGetAllFaqQuery();
  const [addFaqsMutation] = useAddFaqMutation();
  const [deleteFaq] = useLazyDeleteFaqQuery();
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState<any>([]);
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
      is_view: true,
      is_delete: true
  });
  console.log("hide",hidePermission);

 
  
  const permissions = userData?.role?.permissions; 
const permissions_name: string[] = [];  
 
if (permissions && permissions.length > 0) {
  permissions.forEach((permission: any) => {  
    const moduleName = permission.module.name; 
    permissions_name.push(moduleName);  
  });
}

console.log(permissions_name, "permissions_name44"); 

const checkPermission = () => {
  if (permissions_name?.length) {
    let idx = permissions_name.findIndex(
      (moduleName: string) => moduleName ===  "Manage FAQs"
    );

    console.log("Index2333:", idx);

    if (idx !== -1) {
      let data = {
        is_add_edit: permissions[idx]?.is_add_edit,
        is_delete: permissions[idx]?.is_delete,
      };
      setHidePermission(data);
      console.log("lsksjdisjs",hidePermission);
    }
  }
}
useEffect(() => {
  if (userData && userData?.role?.permissions?.length) {
    checkPermission();
  }
}, [userData]);
  const getAllFaqsHandler = async () => {
    setLoading(true);
    try {
      const res = await getAllFaqs({}).unwrap();
      console.log("Responce get all", res);
      if (res?.code === 200) {
        setLoading(false);
        setFaqs(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleAddFaq = async () => {
    const body = {
      question: question,
      answer: answer,
    };
    try {
      const res = await addFaqsMutation(body).unwrap();
      if (res?.code === 201) {
        await getAllFaqsHandler();
         showToast("added successfully");
        setQuestion("");
        setAnswer("");
      }
    } catch (error:any) {
      console.log(error);
      showError(error);
    }
  };

  const handleDeleteFaq = async (id: number) => {
    try {
      const res = await deleteFaq({ id }).unwrap();
      if (res?.code === 200) {
        await getAllFaqsHandler();
        showToast("deleted successfully");
      }
    } catch (error: any) {
      showError(error?.message || "");
    }
  };

  useEffect(() => {
    getAllFaqsHandler();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: "100%" }}>
            <Typography className="custom_label">Question</Typography>
            <TextField
              hiddenLabel
              type="text"
              name="name"
              variant="outlined"
              value={question}
              onChange={(e) => setQuestion(e.target.value.trimStart())}
              placeholder="Question"
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography className="custom_label">Answer</Typography>
            <TextField
              hiddenLabel
              type="text"
              name="name"
              variant="outlined"
              value={answer}
              onChange={(e) => setAnswer(e.target.value.trimStart())}
              placeholder="Answer"
            />
          </FormControl>
          <Box className="faq_btn">
          {hidePermission?.is_add_edit ? ( 
            <Button
              variant="contained"
              disabled={!question || !answer}
              className="btn btn_primary sm"
              onClick={handleAddFaq}
            >
              Add
            </Button>
          ):null}
          </Box>
        </Box>
      </Grid>
      {faqs?.map((item: any, index: any) => (
        <Grid key={index} item xs={12}>
          <Box className="faq_box">
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Question</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="name"
                variant="outlined"
                value={item?.question}
                placeholder="Question"
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Answer</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="name"
                variant="outlined"
                value={item?.answer}
                placeholder="Answer"
              />
            </FormControl>
            <Box className="faq_btn">
            {hidePermission?.is_delete ? ( 
              <Button
                variant="contained"
                className="btn btn_primary sm"
                onClick={() => handleDeleteFaq(item.id)}
              >
                Remove
              </Button>
            ):null}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default MainFaq;
