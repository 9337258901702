import { END_POINTS } from "../constants/url";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  code: number;
  message: string;
};

type putUpdateCommission = {
  commision: string;
};

type getCommision = {
  commision: string;
};

export const SettingsCommision = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCommision: builder.mutation<
      CommonResponseType & { data: putUpdateCommission },
      putUpdateCommission
    >({
      query: (body) => ({
        url: END_POINTS.add_update_commision_fee,
        method: "PUT",
        body,
      }),
    }),

    getCommision: builder.query<
      CommonResponseType & { data: getCommision },
      {}
    >({
      query: ({}) => ({
        url: END_POINTS.get_commision,
        method: "GET",
      }),
    }),
  }),
});

export const { useUpdateCommisionMutation, useLazyGetCommisionQuery } =
  SettingsCommision;
