import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Button, Card, Tab, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";

import {
  Details,
  PurchaseHistory,
  UserTransactions,
  VendorBank,
  VendorInfo,
} from "../../features";
import BankDetails from "../../features/user/bankDetails";
import WalletHistory from "../../features/user/WalletHistory";
import { useLazyGetvendorByIdQuery } from "../../services/manageVendors";
import { Loader } from "../../constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  // const [viewDetails]=useGetvendorByIdQuery();
  const [idDetails, setidDetails] = useState<any>();
  const { id } = useParams();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const VendorDetails = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [value, setValue] = useState<number>(0);
  const [getvendorById] = useLazyGetvendorByIdQuery();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { id } = useParams();
  const fetchDataById = async (id: any) => {
    try {
      const response = await getvendorById({ userId: id }).unwrap();
      console.log("9090", response);
      if (response.code === 200) {
        setByIdDetails(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDataById(id);
    }
  }, [id]);
  console.log("002", id);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Vendor Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-vendors");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Vendor Details" {...a11yProps(0)} />
                <Tab label="User Transactions" {...a11yProps(1)} />
                <Tab label="Wallet History" {...a11yProps(2)} />
                <Tab label="Bank Details" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <VendorInfo data={ByIdDetails} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <UserTransactions />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <WalletHistory />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <VendorBank />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <Loader isLoad={isLoading} />
    </MainContainer>
  );
};

export default VendorDetails;
