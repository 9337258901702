import { END_POINTS } from "../constants/url";
import { Cms } from "../types/General";
import emptySplitApi from "../utils/rtk";
import { CommonResponseType } from "../types/General";

// type CommonResponseType = {
//     message: string,
//     code: number
// }

type PostCmsBody = {
    legal: string,
    privacy_policy: string,
    email: string,
    phone_no: string,
    country_code: string
}
type updateCmsBody = {
    legal: any;
    privacy_policy: any;
    email: any;
    phone_no: any;
    country_code: any;
    about: any; 
}

export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postCms: builder.mutation<
        CommonResponseType & {data: Cms},
        PostCmsBody
        >({
            query: (body) => ({
                url: END_POINTS.add_cms,
                method: "POST",
                body,
            }),
        }),
        getCmsById: builder.query<
        CommonResponseType & {data: Cms},{}>({
            query: () => ({
                url: END_POINTS.cms_by_id,
                method: "GET"
            }),
        }),
        getAllCms: builder.query<
        CommonResponseType & {data: Cms},{}> ({
            query: () => ({
                url: END_POINTS.all_cms,
                method: "GET"
            }),
        }),
        updateCms: builder.mutation<
        CommonResponseType & {data: Cms},
        updateCmsBody
        >({
            query: (body) => ({
                url: END_POINTS.update_cms,
                method: "PUT",
                body
            }),
        }),
        deleteCms: builder.mutation<
        CommonResponseType, {Id: string}>({
            query: ({Id}) => ({
                url: END_POINTS.delete_cms,
                method: "DELETE",
            }),
        }),
        // editVendorProfile: builder.mutation<
        // CommonResponseType & {}
    })
})



export const {
    usePostCmsMutation,
    useLazyGetCmsByIdQuery,
    useLazyGetAllCmsQuery,
    useUpdateCmsMutation,
    useDeleteCmsMutation

} = authApi;