import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { showError, showToast } from "../../../constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { usePutChangePasswordMutation } from "../../../services/auth";
import { Loader } from "../../../constants";
const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [changePasswordMutation, { isLoading }] =
    usePutChangePasswordMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      new_password: "",
      passwordConfirmation: "",
      old_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string()
        .label("Old Password")
        .required("Old password is required."),

      new_password: Yup.string()
        .label("New Password")
        .required("New password is required.")
        .notOneOf(
          [Yup.ref("old_password"), null],
          "New password cannot be same as old password."
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("new_password")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      let body = {
        old_password: values.old_password,
        new_password: values.new_password,
      };

      try {
        const response = await changePasswordMutation(body).unwrap();
        if (response?.code === 200) {
          showToast("Password changed successfully" || "");
          navigate("/dashboard");
        } else {
          showError(response?.message || "Failed to change password");
        }
      } catch (error: any) {
        showError(
          error?.data?.message || "An error occurred while changing password"
        );
        console.log(error, "errorrr");
      }

      setSubmitting(false);
    },
  });

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/dashboard")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Change Password
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Old Password</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Old Password"
                fullWidth
                id="old_password"
                name="old_password"
                type={showOldPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.old_password}
                helperText={
                  formik.touched.old_password && formik.errors.old_password
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">New Password</Typography>
              <TextField
                className="text_field"
                placeholder="New Password"
                fullWidth
                id="new_password"
                name="new_password"
                type={showNewPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new_password}
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Confirm Password</Typography>
              <TextField
                className="text_field"
                placeholder="Confirm Password"
                fullWidth
                id="passwordConfirmation"
                name="passwordConfirmation"
                type={showConfirmPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default ChangePassword;
