import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import NativeSelect from "@mui/material/NativeSelect";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";
import PaidIcon from "@mui/icons-material/Paid";
import { useNavigate } from "react-router-dom";
import { useLazyGetDashBoardDataQuery } from "../../services/dashboard";
import { DashBoardDetails } from "../../types/General";
import {
  useLazyAllUsersGraphQuery,
  useLazyAllEarningGraphQuery,
  useLazyAllVendorsGraphQuery,
} from "../../services/reportAndAnalytics";
import useAuth from "../../hooks/useAuth";
import { Loader } from "../../constants";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const [studentFilter, setStudentFilter] = useState<string>("monthly");
  const [earningFilter, setEarningFilter] = useState<string>("monthly");
  const [DahBoardData] = useLazyGetDashBoardDataQuery();
  const [userFilterUser, setuserFilterUser] = useState<string>("monthly");
  const [userFilterEarning, setuserFilterEarning] = useState<string>("monthly");
  const [userFilterVendor, setuserFilterVendor] = useState<string>("monthly");
  const [userGraph, setUserGraph] = useState<any>([]);
  const [vendorGraph, setVendorGraph] = useState<any>([]);
  const [earningGraph, setEarningGraph] = useState<any>([]);
  const [TotalUsers] = useLazyAllUsersGraphQuery();
  const [EarningGraph] = useLazyAllEarningGraphQuery();
  const [AllVendors] = useLazyAllVendorsGraphQuery();
  const [isLoading, setIsLoading] = useState(false);
  const fetchGraphData = async (interval: any) => {
    try {
      const response = await TotalUsers({ interval }).unwrap();
      console.log("res---totalUser analysis", response);
      if (response?.code === 200) {
        setUserGraph(response?.data);
      } else {
        setUserGraph(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEarningGraph = async (interval: any) => {
    try {
      const response = await EarningGraph({ interval }).unwrap();
      console.log("res---Earning analysis", response);
      setEarningGraph(response?.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchTotalVendorsGraph = async (interval: any) => {
    try {
      const response = await AllVendors({ interval }).unwrap();
      console.log("res---allVendors analysis", response);
      setVendorGraph(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGraphData(userFilterUser);
  }, [userFilterUser]);

  useEffect(() => {
    fetchEarningGraph(userFilterEarning);
  }, [userFilterEarning]);

  useEffect(() => {
    fetchTotalVendorsGraph(userFilterVendor);
  }, [userFilterVendor]);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const [Details, setDetails] = useState<DashBoardDetails>();
  const handleOrderChange = (event: SelectChangeEvent) => {
    setStudentFilter(event.target.value as string);
  };

  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };
  //api call of get

  const fetchDashBoardApi = async () => {
    try {
      const response = await DahBoardData({}).unwrap(); // Await the API call
      console.log(response);
      if (response.code === 200) {
        setDetails(response?.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    fetchDashBoardApi();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Users</h3>
              <h4 className="mn_hdng">{Details?.total_customer}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageservices")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>New Users</h3>
              <h4 className="mn_hdng">{Details?.new_users}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageservices")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>Total Payments</h3>
              <h4 className="mn_hdng">$ {Details?.total_payment}</h4>
            </div>
          </Item>
        </Grid>

        {/* <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageservices")}
          >
            <PaidIcon className="svg_icn" />
            <div>
              <h3>Total Revenue </h3>
              <h4 className="mn_hdng">$ {Details?.total_revenue}</h4>
            </div>
          </Item>
        </Grid> */}
      </Grid>

      <Grid container spacing={2} className="dashGraph">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Users
              <FormControl>
                <NativeSelect
                  value={userFilterUser}
                  onChange={(e) => setuserFilterUser(e.target.value)}
                  inputProps={{
                    name: "report",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value="daily">Day</option>
                  <option value="weekly">Week</option>
                  <option value="monthly">Month</option>
                  <option value="yearly">Year</option>
                </NativeSelect>
              </FormControl>
            </h2>
            <LineChart
              data={{
                labels: Object.keys(userGraph),
                datasets: [
                  {
                    label: "Users",
                    data: Object.values(userGraph),
                    borderColor: "#2593bd",
                    backgroundColor: "#2593bd",
                  },
                ],
              }}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Vendors
              <FormControl>
                <NativeSelect
                  value={userFilterVendor}
                  onChange={(e) => setuserFilterVendor(e.target.value)}
                  inputProps={{
                    name: "report",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value="daily">Day</option>
                  <option value="weekly">Week</option>
                  <option value="monthly">Month</option>
                  <option value="yearly">Year</option>
                </NativeSelect>
              </FormControl>
            </h2>
            <LineChart
              data={{
                labels: Object.keys(vendorGraph),
                datasets: [
                  {
                    label: "Vendors",
                    data: Object.values(vendorGraph),
                    borderColor: "#2593bd",
                    backgroundColor: "#2593bd",
                  },
                ],
              }}
            />
          </div>
        </Grid>
        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Earnings
              <FormControl>
                <NativeSelect
                  value={userFilterEarning}
                  onChange={(e) => setuserFilterEarning(e.target.value)}
                  inputProps={{
                    name: "report",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value="daily">Day</option>
                  <option value="weekly">Week</option>
                  <option value="monthly">Month</option>
                  <option value="yearly">Year</option>
                </NativeSelect>
              </FormControl>
            </h2>
            <LineChart
              data={{
                labels: Object.keys(earningGraph),
                datasets: [
                  {
                    label: "Earnings",
                    data: Object.values(earningGraph),
                    borderColor: "#2593bd",
                    backgroundColor: "#2593bd",
                  },
                ],
              }}
            />
          </div>
        </Grid> */}
      </Grid>
      <Loader isLoad={isLoading} />
    </div>
  );
};

export default DashBoard;
