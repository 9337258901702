import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Loader, showError, showToast } from "../../constants";

import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { isString, useFormik } from "formik";
import * as Yup from "yup";
import {
  usePostSubAdminCreateRoleMutation,
  useLazyGetSubAdminPaginationQuery,
} from "../../services/subAdminRoles";
import {
  usePostAddSubAudminMutation,
  useLazyGetSubAdminByIdQuery,
} from "../../services/subAdmin";
import { useByIdUpdateMutation } from "../../services/subAdmin";
import { useDeleteRoleSubAdminByIdMutation } from "../../services/subAdminRoles";
import { useLazyGetModuleQuery } from "../../services/subAdmin";
import {
  DataType,
  SubAdmin,
  SubAdminUpdateBody,
  roles,
  subAdminRole,
} from "../../types/General";
import PhoneInput from "react-phone-input-2";
import { UploadMedia } from "../../utils/mediaUpload";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { usePostchangeSubAdminRoleStatusMutation } from "../../services/subAdminRoles";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Role {
  name: string;
  enabled: boolean;
}
const AddSubAdmin = () => {
  const navigate = useNavigate();
  const [AdminRoleMainPage, setAdminRoleMainPage] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleNameError, setRoleNameError] = useState("");
  console.log("admin role", AdminRoleMainPage);
  const [loading, setLoading] = useState(false);
  const [getrolelist] = useLazyGetSubAdminPaginationQuery();
  const [AddSubAdmin] = usePostAddSubAudminMutation();
  const [deleteSubAdmin] = useDeleteRoleSubAdminByIdMutation();
  const [rolesData, setRolesData] = useState<Role[]>([]);
  const [newRole, setNewRole] = useState<subAdminRole[]>([]);
  const [createRoll] = usePostSubAdminCreateRoleMutation();
  const [updatenow] = useByIdUpdateMutation();
  const [userData, setUserData] = useState<any>();
  const [updatedpermissions, setupdatedpermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // const [alertMessage, setAlertMessage] = useState("");
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertType, setAlertType] = useState(0);
  // const [image, setImage] = useState<string>("");
  // const [fileName, setFileName] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const [newData, setNewData] = useState<DataType[]>([] as any);
  
  const [page, setPage] = useState(1);
  
  const [country_code, setPhoneCode] = useState("+91");
  const location = useLocation();
  const [SubAdminById] = useLazyGetSubAdminByIdQuery();
  const [callModule] = useLazyGetModuleQuery();
  const { id: userId } = useParams();
  const [updateState, setupdateState] = useState<any>();
  console.log("userid", userId);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [PermissionArray, setPermissionArray] = useState<any>([]);
  console.log(PermissionArray);
  const [getById, setById] = useState<any>();
  console.log("getbyid", getById);
  // const [subAdminData, setSubAdminData] = useState<SubAdmin | null>(null);
  // const[roleAadd,setroleAadd]=useState<any>();
  const [profile_picture, setProfilePicture] = useState("");
  const [id, setRoleId] = useState(1);
  const [imageId, setImageId] = useState(null);
  const [ModuleData, setModuleData] = useState<any>([]);

  const [role, setRole] = useState("default");
  console.log(role, "00");
  const [userStatus] = usePostchangeSubAdminRoleStatusMutation();
///
const userDayta = useAuth();
 
const [hidePermission, setHidePermission] = useState<any>({
  is_add_edit: true,
  is_view: true,
  is_delete: true,
});
 
console.log("hide", hidePermission);

 
const permissionsOfSubAdmin = userDayta?.role?.permissions;
console.log("uhshqbsqy", permissionsOfSubAdmin);
const permissions_name: string[] = [];

if (permissionsOfSubAdmin && permissionsOfSubAdmin.length > 0) {
  permissionsOfSubAdmin.forEach((permission: any) => {
    const moduleName = permission.module.name;
    permissions_name.push(moduleName);
  });
}

console.log(permissions_name, "permissions_name2332");

const checkPermission = () => {
  if (permissions_name?.length) {
    let idx=-1;
     idx = permissions_name.findIndex(
      (moduleName: string) => moduleName ==="Manage Sub-Admin"
    );

    console.log("Index2897:", idx);

    if (idx !== -1) {
      const manageSubAdminPermissions=permissions[idx];
      console.log("02993jhehds",manageSubAdminPermissions);
      if(manageSubAdminPermissions && !manageSubAdminPermissions?.is_add_edit)
      {
         showToast("You don't have the permission to add on this page");
        navigate("/dashboard",{replace:true});
        
      }
      
     else
     {       
      let data = {
        is_add_edit: permissions[idx]?.is_add_edit,
        is_delete: permissions[idx]?.is_delete,
      };
      setHidePermission(data);
      console.log("lsksjdisjs", hidePermission);
    }
  }
  }
  else{
    navigate("/dashboard",{replace:true});
    showError("You don't have permission for this activity");
  }
};

useEffect(() => {
  if (userDayta && userDayta?.role?.permissions?.length) {
    checkPermission();
  }
}, [userDayta]);
///
  // Define handler for role change
  const handleRoleChange = (event: any) => {
    
    setRole(event.target.value);
  };
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [permissions, setPermissions] = useState<DataType[]>([
    {
      module: 1,
      name: "Manage Users",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },

    {
      module: 2,
      name: "Manage vendors",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 3,
      name: "Manage Categories",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 4,
      name: "Manage Transactions",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 5,
      name: "Revenue Management",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 6,
      name: "Manage Notifications",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 7,
      name: "Reports and Analytics",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 8,
      name: "Manage Customer Support",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 9,
      name: "Manage CMS",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },

    {
      module: 10,
      name: "Manage FAQs",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 11,
      name: "Manage AMLs",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
    {
      module: 12,
      name: "Settings",
      is_add_edit: false,
      is_view: false,
      is_delete: false,
    },
  ]);

  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.name === key.name);

    if (type === "is_add_edit") {
      permissions[idx].is_add_edit = !key.is_add_edit;

      if (permissions[idx].is_add_edit) {
        permissions[idx].is_view = true;
      }
    } else if (type === "is_view") {
      permissions[idx].is_view = !key.is_view;

      if (!permissions[idx].is_view) {
        permissions[idx].is_add_edit = false;
        permissions[idx].is_delete = false;
      } else if (permissions[idx].is_add_edit) {
        permissions[idx].is_delete = true;
      }
    } else {
      permissions[idx].is_delete = !key.is_delete;

      if (permissions[idx].is_delete) {
        permissions[idx].is_view = true;
      }
    }

    setPermissions([...permissions]);
  };
  const updateAddRoleStatus = async (id: number, status: boolean) => {
    const body = {
      is_active: status,
    };

    const response = await userStatus({
      body,
      userId: id,
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = newRole.map((x: any) => {
        if (x.id === id) {
          return {
            ...x,
            is_active: status,
          };
        }
        return x;
      });
      setNewRole(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  
  const fetchModule = async () => {
    try {
      console.log("fetch module",permissions);
      const response = await callModule({}).unwrap();
      if (response.code === 200) {
        setModuleData(response?.data);

        if(!userId){
          const updatedPermissions = permissions?.map((permission) => {
          // @ts-ignore
          const module = response?.data?.length ? response?.data?.find((module) => module.id === permission.module) : {};
        
          console.log(module, response?.data,"hdgfhdsgfhjsd");
          
          if (module) {
            return {
              ...permission,
              module: module.name
            };
          } else {
            return permission;
          }
          
        });
        setPermissions(updatedPermissions)
        }
        
        // console.log(updatedPermissions,'lplpplpp');

      }
       
    } catch (error) {
      console.log(error);
    }
  }
  

  const getModuleNumber = (moduleName: any) => {
    switch (moduleName) {
      case "Manage Users":
        return 1;
      case "Manage vendors":
        return 2;
      case "Manage Categories":
        return 3;
      case "Manage Transactions":
        return 4;
      case "Revenue Management":
        return 5;
      case "Manage Notifications":
        return 6;
      case "Reports and Analytics":
        return 7;
      case "Manage Customer Support":
        return 8;
      case "Manage CMS":
        return 9;
      case "Manage FAQs":
        return 10;
      case "Manage AMLs":
        return 11;
      case "Settings":
        return 12;
    }
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      first_name: getById?.data?.first_name || "",
      last_name: getById?.data?.last_name || "",
      email: getById?.data?.email || "",
      phone_no: getById?.data?.phone_no || "",
      profile_picture: imageId,
      sub_role: getById?.data?.sub_role || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Enter a valid email address"),
      first_name: Yup.string()
        .required("First Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
      last_name: Yup.string()
        .required("Last Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
      phone_no: Yup.string()
        .required("Phone number is required")
        .min(5, "Phone number must be at least 5 characters")
        .max(16, "Phone number must be at most 16 characters"),
    }),

    onSubmit: async (values) => {
      
      const selectedPermissions: any = permissions.map((permission) => ({
        module: getModuleNumber(permission?.name),
        is_add_edit: permission.is_add_edit,
        is_view: permission.is_view,
        is_delete: permission.is_delete,
      }));

      const isAtLeastOnePermissionSelected = selectedPermissions.some(
        (permission: { is_add_edit: any; is_view: any; is_delete: any }) =>
          permission.is_add_edit || permission.is_view || permission.is_delete
      );
      if (!isAtLeastOnePermissionSelected) {
        showError("At least one permission should be selected");
        return;
      }
 
      const body = {
        first_name: values.first_name,
        sub_role: role,
        last_name: values.last_name,
        email: values.email,
        country_code: country_code,
        profile_picture: imageId,
        phone_no: values.phone_no,
        permissions: selectedPermissions,
      };
      
      const body2: any = {
        first_name: values.first_name,
        sub_role: role,
        last_name: values.last_name,
        email: values.email,
        country_code: country_code,
        phone_no: values.phone_no,
        profile_picture: imageId,
        role_permissions: selectedPermissions,
      };
     console.log("Sub99",values.first_name);
      console.log("98298393", role);
      if (role === undefined) {
        showError("At least one role should be selected");
        return;
      } else {
        try {
          if (userId) {
            console.log("osmxmsjs2", role);
            const response = await updatenow({ body: body2, userId }).unwrap();

            if (response.code === 201) {
              console.log("jnnswjjx", body);
              showToast("Sub-Admin updated successfully");
              navigate("/manage-subAdmin");
              setupdateState(response);
            } else {
              showError(response?.data?.message || "");
              console.error("Failed to update sub-admin:", response);
            }
          } else {
            const response = await AddSubAdmin(body).unwrap();
            if (response?.code === 201) {
              showToast("Sub-Admin added successfully");
              navigate("/manage-subAdmin");
            } else {
              const errorMessage: any =
                response?.data || response?.message || "Something went wrong";
              showError(errorMessage);
            }
          }
        } catch (error: any) {
          console.error("Error:", error);
          showError(error?.message);
        }
      }
    },
  });
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      const res = await UploadMedia(file);
      if (res?.code === 200) {
        formik.setFieldValue("profile_picture", res?.data[0]?.media_file_url);
        setProfilePicture(res?.data[0]?.media_file_url);
        setImageId(res?.data[0]?.id);
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showError("Error uploading image. Please try again.");
    }
    finally {
      setLoading(false);
    }
  };
  console.log(imageId, "oejene");

  useEffect(() => {
    fetchModule();
  }, []);

  console.log(permissions, "hdgfhdfhdfhdfdshjf");

  ///

  console.log("new role", role);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };
  console.log(ModuleData, "rolling");
  const handleAddRole = async () => {
    try {
      if (AdminRoleMainPage.length < 2) {
        showError("Role name must contain at least two letters");

        return;
      }

      setRoleNameError("");
      let body = {
        name: AdminRoleMainPage,
      };
      console.log(permissions, "adding");
      console.log(permissions, "adding");
      const response = await createRoll(body).unwrap();
      console.log("create roll", response);
      if (response?.code === 201 || response?.code === 200) {
        setAdminRoleMainPage(response?.data?.name);
        setRoleId(response?.data?.id);
        getfetchgetRoll();
        showToast("Role added successfully")
      } else {
        // Show error message
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await deleteSubAdmin({ userId: id }).unwrap();
      console.log("role delete", response);
      if (response?.code === 200) {
        showToast("Role deleted successfully");
        getfetchgetRoll();
      } else {
        showError("Failed to delete Sub-Admin");
      }
    } catch (error: any) {
      console.log(error, "error");
    }
  };

  const getfetchgetRoll = async () => {
    console.log("hitttttt");

    try {
      const response = await getrolelist({}).unwrap();
      console.log("list add page ---", response);
      if (response.code === 200) {
        setNewRole(response?.data || []);
      } else {
        setNewRole([]);
      }
    } catch (error: any) {
      console.log(error, "error");
    }
  };
  console.log(ModuleData, "moduiii");

  const fetchSubAdminApiById = async (userId: any) => {
    try {
      if (!userId) {
        console.error("userId is undefined. Cannot fetch data.");
        return;
      }
      console.log("userId", userId);
      const response = await SubAdminById({ userId: userId }).unwrap();

      console.log(response, "response"); // Check the response

      if (response.code === 200) {
        setById(response?.data);
        setRole(response?.data?.data?.sub_role?.id)
        formik.setFieldValue("first_name", response?.data?.data.first_name);
        formik.setFieldValue("last_name", response?.data?.data.last_name);
        formik.setFieldValue("email", response?.data?.data?.email);
        formik.setFieldValue("phone_no", response?.data?.data?.phone_no);
        // formik.setFieldValue("profile_picture",response?.data?.data?.profile_picture?.id);
        formik.setFieldValue("sub_role", response?.data?.data?.sub_role?.name);
        // formik.setFieldValue("profile_picture",response?.data?.data?.profile_picture?.media_file_url);
        setProfilePicture(
          response?.data?.data?.profile_picture?.media_file_url
        );
        setImageId(response?.data?.data?.profile_picture?.id);

        const updatedPermissions: any = [];
        console.log("res3", response);
        response?.data?.role_permissions.forEach((permission: any) => {
          const { module, is_add_edit, is_view, is_delete } = permission;
          console.log(module, "888888");
          // const name = module.name; // Extract module name
          updatedPermissions.push({
            module: module?.id,
            name: module?.name,
            is_add_edit,
            is_view,
            is_delete,
          });
        });

       

        // Set the updated permissions
        setPermissions(updatedPermissions);
      }
    } catch (error) {
      console.error("Error fetching subAdmin data:", error);
    }
  };

  console.log(ModuleData, "arjoo");
  useEffect(() => {
    if (ModuleData?.length) {
      fetchSubAdminApiById(userId);
    }
  }, [userId, ModuleData]);
  // useEffect(() => {
  //   const profilePictureId = getById.profile_picture?.id;
  //   // Do something with profilePictureId if needed
  // }, [getById.profile_picture?.id, userId]);

  useEffect(() => {
    getfetchgetRoll();
  }, [id]);

  useEffect(() => {
    getfetchgetRoll();
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleChangePhone = (phone_no: any, country: any) => {
    formik.setFieldValue(
      "phone_no",
      phone_no.replace(country.country_code, "")
    );
    setPhoneCode(country.country_code);
  };

  useEffect(() => {
    if (location.state && location.state.tab !== undefined) {
      setValue(location.state.tab);
    }
  }, [location.state]);
  console.log("osmxmsjs", role);

  // useEffect(() => {
  //   setImageId(0); // Reset imageId when userId changes
  // }, [userId]);
  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Sub-Admin</h1>
            <Loader isLoad={loading} />
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Add Role" {...a11yProps(0)} />
                  <Tab label="Add Sub-admin" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography className="custom_label">Add Role</Typography>
                      <TextField
                        hiddenLabel
                        type="text"
                        name="name"
                        variant="outlined"
                        fullWidth
                        className="text_field"
                        placeholder="Add Role"
                        onChange={(e) => setAdminRoleMainPage(e.target.value)}
                        value={AdminRoleMainPage}
                      
                      />
                    </Grid>
                  </Grid>

                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                      onClick={handleAddRole}
                    >
                      Save
                    </Button>
                  </div>
                </CardContent>

                <Grid item xs={12}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="mn_hdng">S.No.</TableCell>
                          <TableCell className="mn_hdng">Role</TableCell>
                          <TableCell className="mn_hdng">Status</TableCell>
                          <TableCell className="mn_hdng">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {newRole?.length > 0 ? (
                          newRole?.map((row, i) => (
                            <TableRow key={row.id}>
                              <TableCell align="center">
                                {(page - 1) * 10 + i + 1}
                              </TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Switch
                                  size="small"
                                  defaultChecked
                                  // checked={row.enabled}
                                  // onChange={() => handleToggle(index)}
                                />
                                {/* <Switch
                                {...label}
                                size="small"
                                checked={row?.is_active}
                                onChange={() =>
                                  updateAddRoleStatus(row?.id, !row?.is_active)
                                }
                              /> */}
                              </TableCell>
                              <TableCell>
                              <IconButton
  onClick={() => {
    setOpen(true);
    setSelectedId(row?.id);
  }}
  style={{
    height: '35px',
    width: '35px',
    minWidth: '35px',
    padding: '0',
    borderRadius: '50%',
    backgroundColor: '#2593bd',
    color: '#fff',
    transition: 'all 0.3s',
  }}
>
  <DeleteIcon />
</IconButton>

                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableBody
                            sx={{
                              padding: "20px",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{ padding: "20px", textAlign: "center" }}
                            >
                              {" "}
                              No roles Added
                            </Typography>
                          </TableBody>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <form onSubmit={formik.handleSubmit}>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {profile_picture ? (
                          <div className="upload_image_preview">
                            <CardMedia
                              component="img"
                              image={profile_picture}
                              alt="photo"
                            />
                            <CancelIcon
                              onClick={() => {
                                setProfilePicture("");
                                setImageId(null);
                              }}
                            />
                          </div>
                        ) : (
                          <Box className="upload_image">
                            <label htmlFor="icon-button-file">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                inputProps={{ accept: "image/png,image/jpeg" }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => handleImageUpload(e)}
                              />
                              <Button
                                component="span"
                                className="upload_image_btn"
                              >
                                <img
                                  src="/static/images/user_placeholder.png"
                                  alt=""
                                />
                                <CameraAltIcon />
                              </Button>
                            </label>
                          </Box>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          First Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="first_name"
                          variant="outlined"
                          fullWidth
                          className="text_field"
                          placeholder="First Name"
                          onBlur={formik.handleBlur}
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.first_name &&
                            formik.errors.first_name
                              ? formik.errors.first_name.toString()
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Last Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type="text"
                          name="last_name"
                          variant="outlined"
                          fullWidth
                          className="text_field"
                          placeholder="Last Name"
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          helperText={
                            formik.touched.last_name && formik.errors.last_name
                              ? formik.errors.last_name.toString()
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">Email</Typography>
                        <TextField
                          disabled={!userId ? false : true}
                          hiddenLabel
                          type="email"
                          name="email"
                          variant="outlined"
                          fullWidth
                          placeholder="Email"
                          className="text_field"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          helperText={
                            formik.touched.email && formik.errors.email
                              ? formik.errors.email.toString()
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Phone Number
                        </Typography>
                        <PhoneInput
                          country="us"
                          placeholder="0 (000) 000-000"
                          enableSearch={true}
                          inputStyle={{ width: "100%" }}
                          value={formik.values.phone_no}
                          onBlur={formik.handleBlur}
                          onChange={(phone_no, country) =>
                            handleChangePhone(phone_no, country)
                          }
                        />

                        {formik.touched.phone_no && formik.errors.phone_no ? (
                          <h6 className="err_msg">
                            {formik.touched.phone_no &&
                              formik.errors.phone_no.toString()}
                          </h6>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Select Role
                        </Typography>

                        <FormControl sx={{ width: "100%" }}>
                      <Select
   className="select_div"
   fullWidth
   labelId="demo-simple-select-label"
   id="demo-simple-select"
   value={role}
   inputProps={{
     "aria-label": "Without label",
   }}
    
  //  {...(userId ? { displayEmpty: true } : { displayEmpty: false })}

   onChange={handleRoleChange}
>  
  <MenuItem value="default" disabled>
    Select
  </MenuItem>

  {newRole?.map(roleItem => (
      <MenuItem key={roleItem.id} value={roleItem.id}>  
        {roleItem.name}
      </MenuItem>
    ))
  }
  {/* {getById?.data?.sub_role && (
    <MenuItem value={getById?.sub_role?.id}>
      {getById?.data?.sub_role?.name}
    </MenuItem>
  )} */}
  
</Select>

                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TableContainer className="table_container">
                          <Box className="heading"></Box>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell className="mn_hdng">
                                  Module
                                </TableCell>
                                <TableCell className="mn_hdng">View</TableCell>
                                <TableCell className="mn_hdng">
                                  Add/Edit
                                </TableCell>
                                <TableCell className="mn_hdng">
                                  Delete
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {permissions?.map((row: any, i) => (
                                <TableRow key={i}>
                                  <TableCell className="mn_hdng">
                                    {row?.name}
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox
                                      {...label}
                                      onChange={() =>
                                        onSelectChange(row, "is_view")
                                      }
                                      checked={row?.is_view || false}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox
                                      {...label}
                                      onChange={() =>
                                        onSelectChange(row, "is_add_edit")
                                      }
                                      checked={row?.is_add_edit || false}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox
                                      {...label}
                                      onChange={() =>
                                        onSelectChange(row, "is_delete")
                                      }
                                      checked={row?.is_delete || false}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                      >
                        Save
                      </Button>
                    </div>
                  </CardContent>
                </form>
              </CustomTabPanel>
            </Box>
          </Card>
        </div>
        <WarnModal
          open={open}
          setOpen={setOpen}
          handleDelete={() => handleDelete(selectedId)}
          name="Sub-Admin"
        />
      </MainContainer>
      <Loader isLoad={isLoading} />
    </>
  );
};

export default AddSubAdmin;
