import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Box, Button, Card, Tab, Tabs, Typography } from "@mui/material";
import { Loader, showError } from "../../../constants";
import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import { useLazyGetUserByIdQuery } from "../../../services/users";
import { Details, PurchaseHistory } from "../../../features";
import BankDetails from "../../../features/user/bankDetails";
import WalletHistory from "../../../features/user/WalletHistory";
import { AllUser } from "../../../types/General";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState<number>(0);
  const [userById] = useLazyGetUserByIdQuery();
  const [useDetails, setUserDetails] = useState<AllUser>();
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageUsers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="User Details" {...a11yProps(0)} />
                <Tab label="Payment History" {...a11yProps(1)} />
                <Tab label="Wallet History" {...a11yProps(2)} />
                <Tab label="Bank Details" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Details />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <PurchaseHistory />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <WalletHistory />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <BankDetails />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <Loader isLoad={isLoading} />
    </MainContainer>
  );
};

export default UserDetails;
