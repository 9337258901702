import { END_POINTS } from "../constants/url";
import emptySplitApi from "../utils/rtk";
 

type CommonResponseType = {
    // data: {
    //   totalCount: number;
    //   count: number;
    //   category: any;
    // };
    data:any;
    code: number;
    message: string;
  };
  type Interval = "monthly" | "weekly" | "yearly"|"daily";

  export const reportAnalyticsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
      AllUsersGraph: builder.query<CommonResponseType & { data: any; },  { interval: Interval }>({
        query: ({interval}) => ({
          url: `${END_POINTS.TotalUsersGraph}?interval=${interval}`,
          method: "GET",
        }),
      }),
  
      AllEarningGraph: builder.query<CommonResponseType & { data: any }, { interval: Interval }>({
        query: ({ interval }) => ({
          url: `${END_POINTS.AllEarningGraph}?interval=${interval}`,
          method: "GET",
        }),
      }),
  
      AllVendorsGraph: builder.query<CommonResponseType & { data: any },{interval: Interval}>({
        query: ({interval}) => ({
          url: `${END_POINTS.TotalVendors}?interval=${interval}`,
          method: "GET",
        }),
      }),
    }),
  });
  
  export const { 
    useLazyAllUsersGraphQuery,
    useLazyAllEarningGraphQuery,
    useLazyAllVendorsGraphQuery
  } = reportAnalyticsApi;
  