import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const VendorInfo = ({ data }: { data: any }) => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  console.log("hide", hidePermission);

  const permissions = userData?.role?.permissions;
  console.log("uhshqbsqy", permissions);
  const permissions_name: string[] = [];

  if (permissions && permissions.length > 0) {
    permissions.forEach((permission: any) => {
      const moduleName = permission.module.name;
      permissions_name.push(moduleName);
    });
  }

  console.log(permissions_name, "permissions_name2");

  const checkPermission = () => {
    if (permissions_name?.length) {
      let idx = permissions_name.findIndex(
        (moduleName: string) => moduleName === "Manage vendors"
      );

      console.log("Index2897:", idx);

      if (idx !== -1) {
        const manageVendorsPermission = permissions[idx];
        console.log("02993jhehds", manageVendorsPermission);
        if (manageVendorsPermission && !manageVendorsPermission?.is_add_edit) {
          showToast("You don't have the permission to add on this page");
          navigate("/dashboard", { replace: true });
        } else {
          let data = {
            is_add_edit: permissions[idx]?.is_add_edit,
            is_delete: permissions[idx]?.is_delete,
          };
          setHidePermission(data);
          console.log("lsksjdisjs", hidePermission);
        }
      }
    } else {
      navigate("/dashboard", { replace: true });
      showError("You don't have permission for this activity");
    }
  };

  useEffect(() => {
    if (userData && userData?.role?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              {data?.profile_picture?.media_file_url ? (
                <img
                  src={data?.profile_picture?.media_file_url}
                  style={{ width: "70px", height: "50px" }}
                  alt="Profile Picture"
                />
              ) : (
                <AccountCircleIcon sx={{ width: 100, height: 100 }} />
              )}
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Name</Typography>
                  <Typography component="p">
                    {" "}
                    {data.first_name + " " + data.last_name}{" "}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p">+{data.phone_no}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p">{data.email}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Account Status</Typography>
                  <Typography component="p">
                    {" "}
                    {data?.is_active === true ? "Active" : "Inactive"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Wallet Balance</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Total Withdraw</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">QR code</Typography>
                  <img
                    src="/static/images/qr.png"
                    style={{ width: "150px", height: "150px" }}
                  />
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Box>
                    <Typography component="h5">
                      Document (front image)
                    </Typography>
                    <Card sx={{ p: 2 }}>
                      <figure style={{ paddingTop: "10px", margin: "0px" }}>
                        {data?.documents?.[0]?.front_side?.media_file_url ? (
                          <img
                            src={
                              data?.documents?.[0]?.front_side?.media_file_url
                            }
                            style={{ width: "300px", height: "200px" }}
                            alt="Front Side"
                          />
                        ) : (
                          <img
                            src={"/static/images/govId.png"}
                            style={{ width: "300px", height: "200px" }}
                            alt="Front Side"
                          />
                        )}
                      </figure>

                      <Typography component="h5">
                        Document ID : {data?.documents?.[0]?.front_side?.id}
                      </Typography>
                    </Card>
                  </Box>
                  <Box>
                    <Typography component="h5">
                      Document (back image)
                    </Typography>
                    <Card sx={{ p: 2 }}>
                      <figure style={{ paddingTop: "10px", margin: "0px" }}>
                        {data?.documents?.[0]?.back_side?.media_file_url ? (
                          <img
                            src={
                              data?.documents?.[0]?.back_side?.media_file_url
                            }
                            style={{ width: "300px", height: "200px" }}
                            alt="Front Side"
                          />
                        ) : (
                          <img
                            src={"/static/images/govId.png"}
                            style={{ width: "300px", height: "200px" }}
                            alt="Front Side"
                          />
                        )}
                      </figure>
                      <Typography component="h5">
                        Document ID : {data?.documents?.[0]?.back_side?.id}
                      </Typography>
                    </Card>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VendorInfo;
