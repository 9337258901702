import { END_POINTS } from "../constants/url";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  code: number;
};

type PostLoginBody = {
  email: string;
  password: string;
};

type PostForgotPasswordBody = {
  email: string;
};

type PostVerifyOtpBody = {
  email: string;
  otp: string;
};

type PostVerifyOtpResponse = {
  statusCode: number;
  message: string;
  data: any;
};

type PostSetPasswordBody = {
  password: string;
};

type PostSetPasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};

type PostChangePasswordBody = {
  oldPassword: string;
  password: string;
};

type PostResetPassword = {
  email: string;
  password: string;
};

type EditProfileBody = {
  email: string;
  first_name: string;
  last_name: string;
  country_code: string;
  phone_no: string;
  profile_picture: any;
};

type GetUserParams = {
  name: string;
  userName: string;
  phone: string;
  email: string;
  planName: string;
  planPrice: string;
  postedAds: string;
  earnings: string;
};

type PostChangePasswordResponse = {
  old_password: string;
  new_password: string;
};

type PostVerificationStatus={
  verification_status:any
}



export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<
      CommonResponseType & { data: User },
      PostLoginBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postForgotPassword: builder.mutation<
      CommonResponseType & { data: User },
      PostForgotPasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.forgot_password,
        method: "POST",
        body,
      }),
    }),

    postOtp: builder.mutation<CommonResponseType, PostVerifyOtpBody>({
      query: (body) => ({
        url: END_POINTS.otp,
        method: "POST",
        body,
      }),
    }),

    postResetPass: builder.mutation<CommonResponseType, PostResetPassword>({
      query: (body) => ({
        url: END_POINTS.reset_password,
        method: "POST",
        body,
      }),
    }),

    putChangePassword: builder.mutation<
      CommonResponseType,
      PostChangePasswordResponse
    >({
      query: (body) => ({
        url: END_POINTS.change_password,
        method: "PUT",
        body,
      }),
    }),


    

    /////GET
    getAdmin: builder.query<CommonResponseType & { data: User }, {}>({
      query: () => ({
        url: END_POINTS.getAdmin,
        method: "GET",
      }),
    }),

    getAllUsers: builder.query<CommonResponseType & { data: User }, { type: number }>({
      query: ({ type }) => ({
        url: `${END_POINTS.manage_user}${type}/`,
        method: "GET",
      }),
    }),

    PuteditProfile: builder.mutation<
      CommonResponseType & { statusCode: number; data: User; message: string },
      EditProfileBody
    >({
      query: (body) => ({
        url: END_POINTS.getAdmin, 
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostForgotPasswordMutation,
  usePostOtpMutation,
  usePostResetPassMutation,
  useLazyGetAdminQuery,
  usePuteditProfileMutation,
  usePutChangePasswordMutation,
  useLazyGetAllUsersQuery,

} = authApi;
