import Compressor from "compressorjs";
import { getFromStorage } from "../constants/storage";
import { API_URL, END_POINTS } from "../constants/url";
import { STORAGE_KEYS } from "../constants/storageKeys";

interface UploadResponse {
  code: number;
  data: any;
  message: string;
}
export const UploadMedia = async (imageObject: any): Promise<UploadResponse> => {
  const formData = new FormData();
  formData.append("media", imageObject);

  const headers = {
    Accept: "application/json",
  };

  try {
    const res = await fetch(API_URL + END_POINTS.upload, {
      method: "POST",
      headers,
      body: formData,
    });
    
    if (!res.ok) {
      throw new Error(`Failed to upload image: ${res.statusText}`);
    }
    
    const response = await res.json();
    return response;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};


export const UploadVideo = (imageObject: any): Promise<UploadResponse> => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append("image", imageObject as any);
    let headers = {
      Accept: "application/json",
    };

    try {
      const res = await fetch(API_URL + END_POINTS.upload, {
        method: "POST",
        headers,
        body: formData,
      });
      let response = await res.json();
      resolve(response); // Resolve the Promise with the response
    } catch (error) {
      console.log(error, ">>>>>>>>>");
      reject(error); // Reject the Promise with the error
    }
  });
};

export const Uploadpdf = async (imageObject: any) => {
  console.log("imageObject, ", imageObject);

  const formData = new FormData();

  formData.append("media", imageObject as any);

  const getToken = await getFromStorage(STORAGE_KEYS.token);
  const token = getToken ? JSON.parse(`${getToken}`) : null;
  let headers = {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    const res = await fetch(API_URL + END_POINTS.upload, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
