import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PaymentIcon from "@mui/icons-material/Payment";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { SIDEBAR_WIDTH } from "../constants";
import SettingsIcon from "@mui/icons-material/Settings";
import QuizIcon from "@mui/icons-material/Quiz";
import PaidIcon from "@mui/icons-material/Paid";
import MapIcon from "@mui/icons-material/Map";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ShieldIcon from "@mui/icons-material/Shield";
import { PropsWithoutRef, useEffect, useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";

import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import TransactionIcon from "@mui/icons-material/Money";
import RevenueIcon from "@mui/icons-material/Collections";
import ReportsIcon from "@mui/icons-material/Report";
import CMSIcon from "@mui/icons-material/CallMergeSharp";
import FAQIcon from "@mui/icons-material/FormatQuote";
import useAuth from "../hooks/useAuth";
import CustomerSupportIcon from "@mui/icons-material/CallMadeSharp";
import AMLIcon from "@mui/icons-material/AddModerator";
import Report from "@mui/icons-material/Report";
import Category from "@mui/icons-material/Category";
import Money from "@mui/icons-material/Money";
import { Notifications } from "@mui/icons-material";
import Support from "@mui/icons-material/Support";
import Settings from "@mui/icons-material/Settings";
import SubAdmin from "@mui/icons-material/People";
import Faqs from "@mui/icons-material/FormatQuote";
interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const userData = useAuth();
  const navigate = useNavigate();
  const [permission, setPermission] = useState("");
  console.log(permission);
  console.log("12", userData);
  const handleNavigate = () => {
    if (list.find((item: any) => item?.name !== "Dashboard")) {
      navigate("/dashboard");
    }
  };

  const items = [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      name: "Manage Users",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      name: "Manage vendors",
      icon: <PeopleIcon />,
      key: "3",
      selected: "/manage-vendors",
    },
    {
      name: "Manage Categories",
      icon: <CategoryIcon />,
      key: "4",

      selected: "/manage-categories",
    },
    // {
    //   name: "Manage Transactions",
    //   icon: <CurrencyExchangeIcon />,
    //   key: "5",
    //   selected: "/manage-transactions",
    // },
    // {
    //   name: "Revenue Management",
    //   icon: <PaidIcon />,
    //   key: "6",

    //   selected: "/manage-revenue",
    // },
    {
      name: "Manage Notifications",
      icon: <NotificationsActiveIcon />,
      key: "7",
      selected: "/manage-notifications",
    },
    {
      name: "Report and Analysis",
      icon: <BarChartIcon />,
      key: "8",

      selected: "/analytics",
    },

    {
      name: "Manage Customer Support",
      icon: <HelpCenterIcon />,
      key: "9",
      selected: "/customer-support",
    },
    {
      name: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      key: "10",

      selected: "/cms",
    },
    {
      name: "Manage FAQs",
      icon: <QuizIcon />,
      key: "11",
      selected: "/faq",
    },

    {
      name: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "12",

      selected: "/manage-subAdmin",
    },
    {
      name: "Manage AMLs",
      icon: <ShieldIcon />,
      key: "13",
      selected: "/manage-AMLs",
    },
    {
      name: "Settings",
      icon: <SettingsIcon />,
      key: "14",
      selected: "/settings",
    },
  ];
  const [list, setList] = useState<any>(
    userData?.role?.sub_role === "admin" ? items : userData?.role?.permissions
  );
  console.log("939092923", userData);
  console.log("55", userData?.role?.permissions);
  useEffect(() => {
    if (userData?.role?.sub_role === "admin") {
      setList(items);
    } else if (userData?.role?.permissions?.length) {
      const permission = userData?.role?.permissions;
      const filteredItems = items.filter((item) => {
        if (item.name === "Dashboard") {
          return true;
        } else {
          // Check if the user has permission for this item
          const idx = permission.findIndex(
            (ele: any) => ele?.module?.name === item?.name
          );
          return idx > -1 && permission[idx].is_view;
        }
      });
      setList(filteredItems);
    }
  }, [userData]);

  //   <>
  //     <Toolbar
  //       className="sidebar-logo"
  //       sx={{
  //         position: "relative",
  //       }}
  //     >
  //       <figure>
  //         <img
  //           src="/static/images/logo.png"
  //           alt=""
  //           onClick={() => navigate("/dashboard")}
  //         />
  //       </figure>

  //       <IconButton
  //         sx={{
  //           position: "absolute",
  //           top: "10px",
  //           right: "10px",
  //           color: "white",
  //           display: { lg: "none" },
  //         }}
  //         onClick={handleSidebarToggle}
  //       >
  //         <CloseIcon />
  //       </IconButton>
  //     </Toolbar>

  //     <List sx={{ flexGrow: 1 }} className="sidebr-lst">
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/dashboard"}
  //         onClick={() => navigate("/dashboard")}
  //       >
  //         <ListItemIcon>
  //           <DashboardIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Dashboard" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manageusers"}
  //         onClick={() => navigate("/manageusers")}
  //       >
  //         <ListItemIcon>
  //           <PeopleIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Users" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-vendors"}
  //         onClick={() => navigate("/manage-vendors")}
  //       >
  //         <ListItemIcon>
  //           <PeopleIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Vendors" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-categories"}
  //         onClick={() => navigate("/manage-categories")}
  //       >
  //         <ListItemIcon>
  //           <CategoryIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Categories" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-transactions"}
  //         onClick={() => navigate("/manage-transactions")}
  //       >
  //         <ListItemIcon>
  //           <CurrencyExchangeIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Transactions" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-revenue"}
  //         onClick={() => navigate("/manage-revenue")}
  //       >
  //         <ListItemIcon>
  //           <PaidIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Revenue Management" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-notifications"}
  //         onClick={() => navigate("/manage-notifications")}
  //       >
  //         <ListItemIcon>
  //           <NotificationsActiveIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Notifications" />
  //       </ListItemButton>
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/analytics"}
  //         onClick={() => navigate("/analytics")}
  //       >
  //         <ListItemIcon>
  //           <BarChartIcon />
  //         </ListItemIcon>
  //         <ListItemText
  //           className="lstitm-txt"
  //           primary="Reports and Analytics"
  //         />
  //       </ListItemButton>
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/customer-support"}
  //         onClick={() => navigate("/customer-support")}
  //       >
  //         <ListItemIcon>
  //           <HelpCenterIcon />
  //         </ListItemIcon>
  //         <ListItemText
  //           className="lstitm-txt"
  //           primary="Manage Customer Support"
  //         />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/cms"}
  //         onClick={() => navigate("/cms")}
  //       >
  //         <ListItemIcon>
  //           <SummarizeTwoToneIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage CMS" />
  //       </ListItemButton>
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/faq"}
  //         onClick={() => navigate("/faq")}
  //       >
  //         <ListItemIcon>
  //           <QuizIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary=" Manage FAQs" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-subAdmin"}
  //         onClick={() => navigate("/manage-subAdmin")}
  //       >
  //         <ListItemIcon>
  //           <PersonAddIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage Sub-Admin" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/manage-AMLs"}
  //         onClick={() => navigate("/manage-AMLs")}
  //       >
  //         <ListItemIcon>
  //           <ShieldIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Manage AMLs" />
  //       </ListItemButton>

  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/settings"}
  //         onClick={() => navigate("/settings")}
  //       >
  //         <ListItemIcon>
  //           <SettingsIcon />
  //         </ListItemIcon>
  //         <ListItemText className="lstitm-txt" primary="Settings" />
  //       </ListItemButton>
  //     </List>
  //   </>
  // );

  // return (
  //   <Box
  //     component="nav"
  //     sx={{ flexShrink: { lg: 0 } }}
  //     className="sidebar-main-div"
  //   >
  //     <Drawer
  //       className="sidebar_drawer"
  //       variant="temporary"
  //       open={mobileOpen}
  //       onClose={handleSidebarToggle}
  //       ModalProps={{
  //         keepMounted: true,
  //       }}
  //       sx={{
  //         display: { xs: "block", lg: "none" },
  //         "& .MuiDrawer-paper": {
  //           boxSizing: "border-box",
  //           width: SIDEBAR_WIDTH,
  //         },
  //       }}
  //     >
  //       {drawer}
  //     </Drawer>
  //     <Drawer
  //       variant="permanent"
  //       className="sidebar_drawer"
  //       sx={{
  //         display: { xs: "none", lg: "block" },
  //         "& .MuiDrawer-paper": {
  //           width: SIDEBAR_WIDTH,
  //           boxSizing: "border-box",
  //         },
  //       }}
  //       open
  //     >
  //       {drawer}
  //     </Drawer>
  //   </Box>
  // );
  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
            right: 0,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={handleNavigate}
            />
          </figure>

          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.name} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
            right: 0,
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={handleNavigate}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.name} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      // className="lst-itm"
                      // selected={
                      //   location.pathname.includes(item.selected) === item.selected
                      // }
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
        {/* {drawer} */}
      </Drawer>
    </Box>
  );
}
