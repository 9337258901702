import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useDeleteCategoryMutation,
  usePostGetAllCategoriesMutation,
  useUpdateCategoryStatusMutation,
} from "../../services/categories";
import { Loader, showError, showToast } from "../../constants";
import { Category } from "../../types/General";
import WarnModal from "../../components/WarnModal";
import { isValidInput } from "../../utils/validations";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";

const ManageCategories = () => {
  const navigate = useNavigate();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [allCategory, { isLoading }] = usePostGetAllCategoriesMutation();
  const [categoryStatus] = useUpdateCategoryStatusMutation();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [categoryData, setCategoryData] = useState<Category[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true,
  });
  const permissions = userData?.role?.permissions;
  const permissions_name: string[] = [];

  if (permissions && permissions.length > 0) {
    permissions.forEach((permission: any) => {
      const moduleName = permission.module.name;
      permissions_name.push(moduleName);
    });
  }
  const checkPermission = () => {
    if (permissions_name?.length) {
      let idx = permissions_name.findIndex(
        (moduleName: string) => moduleName === "Manage Categories"
      );

      console.log("Index2333:", idx);

      if (idx !== -1) {
        const manageCategoryPermission = permissions[idx];
        if (manageCategoryPermission && !manageCategoryPermission?.is_view) {
          showError(
            "You dont't have the permission to view Categoey Details page"
          );
          navigate("/dashboard", { replace: true });
        }
        let data = {
          is_add_edit: permissions[idx]?.is_add_edit,
          is_delete: permissions[idx]?.is_delete,
        };
        setHidePermission(data);
        console.log("lsksjdisjs", hidePermission);
      }
    }
  };
  console.log("hide", hidePermission);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const updateCategoryStatus = async (id: number, status: boolean) => {
    const body = {
      is_active: status,
    };

    const response = await categoryStatus({
      body,
      categoryId: id,
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = categoryData.map((x: any) => {
        if (x.id === id) {
          return {
            ...x,
            is_active: status,
          };
        }
        return x;
      });
      setCategoryData(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await deleteCategory({ categoryId: id }).unwrap();
      if (response?.code === 200) {
        showToast("category deleted successfully");
        GetAllCategories();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const GetAllCategories = async () => {
    let body = {
      length: 10,
      start: page,
      search: debouncedSearchTerm,
    };
    try {
      const response = await allCategory(body).unwrap();
      if (response.code === 200) {
        setCategoryData(response?.data || []);
        setTotalCount(response?.total_count);
      } else {
        setCategoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    } finally {
    }
  };

  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Electricity"), createData("Phone Bills")];

  useEffect(() => {
    GetAllCategories();
  }, [debouncedSearchTerm, page]);
  useEffect(() => {
    if (userData && userData?.role?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Categories</h1>
        </div>
        <Loader isLoad={loading} />
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.is_add_edit ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-categories/add")}
                  >
                    Add Category
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Category Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryData?.length > 0
                    ? categoryData?.map((row, i) => (
                        <TableRow>
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell>
                            <figure className="user_img">
                              <img
                                src={
                                  row?.category_image?.media_file_url
                                    ? row?.category_image?.media_file_url
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.category_name}</TableCell>
                          <TableCell>
                            <Switch
                              {...label}
                              size="small"
                              checked={row?.is_active}
                              onChange={() =>
                                updateCategoryStatus(row?.id, !row?.is_active)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              {hidePermission?.is_add_edit ? (
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-categories/edit/${row?.id}`
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              ) : null}
                              {hidePermission?.is_delete ? (
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        {categoryData?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={categoryData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Category"
      />
    </MainContainer>
  );
};

export default ManageCategories;
