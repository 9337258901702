import { END_POINTS } from "../constants/url";
import { AllUser, manage_User,ByIdUserDetail } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
    total_records?: any;
    // data: any;
    message: string;
    code:number
  };

type Pending_Users={
    length:number,
    start:number,
    search:string
  };
  
  type Approved_Users={
    length:number,
    start:number,
    search:string
  };
  type Rejected_Users={
    length:number,
    start:number,
    search:string
  };
  type Change_Verification_Status={
    verification_status:number
  }
  

  type UserStatusParams={
    body:{
      verification_status:number;
    }
    id:number
  }
  type active_status = {
    body: { is_active: boolean };
    userId?: number;
  };
  
type EditProfileBody= {
  email: string ; 
    first_name: string; 
    last_name: string; 
    country_code: string;
     phone_no: string;
       
      profile_picture:any
    }
    type user_current_state={
  verification_status:number,
  length: number,
  start: number,
  search: string

    }
    
export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        
  /////management POST
  post_Pending_Users:builder.mutation<CommonResponseType,Pending_Users> 
  ({
    query:(body)=>({
      url: END_POINTS.pending_users,
      method: "POST",
      body,
    })
  }),
  post_Rejected_Users:builder.mutation<CommonResponseType,Rejected_Users> 
  ({
    query:(body)=>({
      url: END_POINTS.rejected_users,
      method: "POST",
      body,
    })
  }),
  PostchangeUserStatus: builder.mutation<CommonResponseType, active_status>({
    query: ({ body, userId }) => ({
      url: `${END_POINTS.active_status}${userId}/`,
      method: "POST",
      body,
    }),
  }),
  post_Approved_Users:builder.mutation<CommonResponseType,Approved_Users> 
  ({
    query:(body)=>({
      url: END_POINTS.approved_users,
      method: "POST",
      body,
    })
  }),
  post_Change_Verification_Status:builder.mutation<CommonResponseType,Change_Verification_Status> 
  ({
    query:(body)=>({
      url: END_POINTS.verification_status,
      method: "POST",
      body,
    })
  }),
  // post_is_active:builder.mutation<CommonResponseType,active_status> 
  // ({
  //   query:(body)=>({
  //     url: END_POINTS.active_status,
  //     method: "POST",
  //     body,
  //   })
  // }),
  
  postgetAllUsers: builder.query<CommonResponseType & {data: manage_User[]},user_current_state>
  ({
    query: (body) => ({
      url: `${END_POINTS.manage_user}`,
      method: "POST",
     body,
    }),
  }),
  
  //////
    /////GET 
     
   
    
    
    GetUserById: builder.query<CommonResponseType & { data: ByIdUserDetail }, {userId:any}>({
      query: ({userId}) => ({
        url: `${END_POINTS.userById}${userId}/`,
        method: "GET",
      }),
    }),
  ///put
   

///delete
deleteUser_by_admin: builder.mutation<CommonResponseType, { userId: number }>({
  query: ({ userId }) => ({
    url: `${END_POINTS.delete_user}${userId}/`,
    method: "DELETE",
  }),
}),

PostChangeRequest: builder.mutation<CommonResponseType, UserStatusParams>({
  query: ({ id,body }) => ({
    url: `${END_POINTS.changeRequest}${id}/`, // Append the ID to the URL
    method: "POST",
    body,
  }),
}),
  }),
});
 
export const { 
  usePost_Pending_UsersMutation,
  usePost_Rejected_UsersMutation,
  usePost_Approved_UsersMutation,
  usePost_Change_Verification_StatusMutation,
  // usePost_is_activeMutation,
  useLazyGetUserByIdQuery,
  useDeleteUser_by_adminMutation,
  useLazyPostgetAllUsersQuery,
  usePostChangeRequestMutation,
  usePostchangeUserStatusMutation
} = authApi;
