import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { FormControl, Grid } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import {
  useLazyAllUsersGraphQuery,
  useLazyAllEarningGraphQuery,
  useLazyAllVendorsGraphQuery,
} from "../../services/reportAndAnalytics";
import { Loader } from "../../constants";

const Analytics = () => {
  const [userFilterUser, setuserFilterUser] = useState<string>("monthly");
  const [userFilterEarning, setuserFilterEarning] = useState<string>("monthly");
  const [userFilterVendor, setuserFilterVendor] = useState<string>("monthly");
  const [userGraph, setUserGraph] = useState<any>([]);
  const [vendorGraph, setVendorGraph] = useState<any>([]);
  const [earningGraph, setEarningGraph] = useState<any>([]);
  const [TotalUsers] = useLazyAllUsersGraphQuery();
  const [EarningGraph] = useLazyAllEarningGraphQuery();
  const [AllVendors] = useLazyAllVendorsGraphQuery();
  const [isLoading, setIsLoading] = useState(false);
  const fetchGraphData = async (interval: any) => {
    try {
      const response = await TotalUsers({ interval }).unwrap();
      console.log("res---totalUser analysis", response);
      if (response?.code === 200) {
        setUserGraph(response?.data);
      } else {
        setUserGraph(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEarningGraph = async (interval: any) => {
    try {
      const response = await EarningGraph({ interval }).unwrap();
      console.log("res---Earning analysis", response);
      setEarningGraph(response?.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchTotalVendorsGraph = async (interval: any) => {
    try {
      const response = await AllVendors({ interval }).unwrap();
      console.log("res---allVendors analysis", response);
      setVendorGraph(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGraphData(userFilterUser);
  }, [userFilterUser]);

  useEffect(() => {
    fetchEarningGraph(userFilterEarning);
  }, [userFilterEarning]);

  useEffect(() => {
    fetchTotalVendorsGraph(userFilterVendor);
  }, [userFilterVendor]);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Reports and Analytics</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Users
                <FormControl>
                  <NativeSelect
                    value={userFilterUser}
                    onChange={(e) => setuserFilterUser(e.target.value)}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Day</option>
                    <option value="weekly">Week</option>
                    <option value="monthly">Month</option>
                    <option value="yearly">Year</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart
                data={{
                  labels: Object.keys(userGraph),
                  datasets: [
                    {
                      label: "Users",
                      data: Object.values(userGraph),
                      borderColor: "#2593bd",
                      backgroundColor: "#2593bd",
                    },
                  ],
                }}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Vendors
                <FormControl>
                  <NativeSelect
                    value={userFilterVendor}
                    onChange={(e) => setuserFilterVendor(e.target.value)}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Day</option>
                    <option value="weekly">Week</option>
                    <option value="monthly">Month</option>
                    <option value="yearly">Year</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart
                data={{
                  labels: Object.keys(vendorGraph),
                  datasets: [
                    {
                      label: "Vendors",
                      data: Object.values(vendorGraph),
                      borderColor: "#2593bd",
                      backgroundColor: "#2593bd",
                    },
                  ],
                }}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Total Earnings
                <FormControl>
                  <NativeSelect
                    value={userFilterEarning}
                    onChange={(e) => setuserFilterEarning(e.target.value)}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Day</option>
                    <option value="weekly">Week</option>
                    <option value="monthly">Month</option>
                    <option value="yearly">Year</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart
                data={{
                  labels: Object.keys(earningGraph),
                  datasets: [
                    {
                      label: "Earnings",
                      data: Object.values(earningGraph),
                      borderColor: "#2593bd",
                      backgroundColor: "#2593bd",
                    },
                  ],
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Loader isLoad={isLoading} />
    </MainContainer>
  );
};

export default Analytics;
