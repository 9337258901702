// index.tsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";

import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";
import { UploadMedia } from "../../utils/mediaUpload";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../../reducers/authSlice";
import {
  getFromStorage,
  Loader,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import { isString } from "../../utils/validations";
import { usePuteditProfileMutation } from "../../services/auth";
const Profile = () => {
  const user = useAuth();
  console.log(user, "====");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [editProfileMutation] = usePuteditProfileMutation();
  const [country_code, setPhoneCode] = useState("+91");
  const [profile_picture, setProfilePicture] = useState("");
  const [imageId, setImageId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Retrieve profile picture URL from local storage
    // const storedProfilePicture = localStorage.getItem("profile_picture");
    // setProfilePicture(storedProfilePicture || "");

    // Manually set Formik values when user data is available
    if (user) {
      formik.setValues({
        email: user.email || "",
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        phone_no: user.phone_no || "",
        profile_picture: profile_picture,
      });
    }
  }, [user]);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        setImageId(null);
        return;
      }
      const res = await UploadMedia(file);
      if (res?.code === 200) {
        formik.setFieldValue("profile_picture", res?.data[0]?.media_file_url); // Set the value in Formik
        setProfilePicture(res?.data[0]?.media_file_url); // Update state
        setImageId(res?.data[0]?.id);
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      showError("Error uploading image. Please try again.");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      phone_no: user?.phone_no || "",
      profile_picture: profile_picture,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Enter a valid email address"),
      first_name: Yup.string()
        .required("First Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
      last_name: Yup.string()
        .required("Last Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
      phone_no: Yup.string()
        .required("Phone number is required")
        .min(5, "Phone number must be at least 5 characters")
        .max(16, "Phone number must be at most 16 characters"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!formik.isValid) return; // Prevent submission if form is invalid
      try {
        setSubmitting(true);

        let body = {
          email: formik.values.email || "",
          first_name: formik.values.first_name || "",
          last_name: formik.values.last_name || "",
          country_code: country_code,
          phone_no: formik.values.phone_no || "",
          profile_picture: imageId, // Get profile_picture from formik values
        };

        const response = await editProfileMutation(body).unwrap();
        console.log("res", response);
        if (response?.code === 200) {
          const token = getFromStorage(STORAGE_KEYS.token);
          showToast("Profile updated successfully");
          navigate("/dashboard");
          console.log(response?.data);
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            })
          );
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleChangePhone = (phone_no: any, country: any) => {
    formik.setFieldValue(
      "phone_no",
      phone_no.replace(country.country_code, "")
    );
    setPhoneCode(country.country_code);
  };
  console.log("kjsmnhss", country_code);
  useEffect(() => {
    setPhoneCode(user?.country_code || "");
    setProfilePicture(user?.profile_picture?.media_file_url || "");
    setImageId(user?.profile_picture?.id || "");
  }, [user]);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <MainContainer>
      <div className="main_layout">
        <div className="dashboard">
          <h1>Edit User Profile</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {profile_picture ? (
                    <div className="upload_image_preview">
                      <CardMedia
                        component="img"
                        image={profile_picture}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={(e) => {
                          e.preventDefault();
                          setProfilePicture("");
                          setImageId(null);
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{ accept: "image/png,image/jpeg" }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleImageUpload(e)
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            src="/static/images/user_placeholder.png"
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">First Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="first_name"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="First Name"
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                        ? formik.errors.first_name.toString()
                        : ""
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Last Name</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="last_name"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Last Name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                        ? formik.errors.last_name.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    hiddenLabel
                    type="email"
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder={user?.email || "Email"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled
                    helperText={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email.toString()
                        : ""
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Phone Number</Typography>
                  <PhoneInput
                    country="us"
                    placeholder="0 (000) 000-000"
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                    value={formik.values.phone_no}
                    onChange={(phone_no, country) =>
                      handleChangePhone(phone_no, country)
                    }
                    onBlur={formik.handleBlur}
                  />

                  {formik.touched.phone_no && formik.errors.phone_no ? (
                    <h6 className="err_msg">
                      {formik.touched.phone_no &&
                        formik.errors.phone_no.toString()}
                    </h6>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  disabled={formik.isSubmitting}
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
      <Loader isLoad={isLoading} />
    </MainContainer>
  );
};

export default Profile;
