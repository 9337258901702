import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails/:id" element={<UserDetails />} />
      <Route path="/manageusers/usersform" element={<UsersForm />} />
      <Route path="/manageusers/usersform/:id" element={<UsersForm />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />

      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route
        path="/customer-support/details"
        element={<Pages.QueryDetails />}
      />

      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/edit/:id"
        element={<Pages.AddCategories />}
      />
      <Route
        path="/manage-categories/details"
        element={<Pages.SubCategories />}
      />

      <Route
        path="/manage-transactions"
        element={<Pages.ManageTransactions />}
      />
      <Route
        path="/manage-transactions/details"
        element={<Pages.TransactionDetails />}
      />
      <Route path="/manage-reviews/details" element={<Pages.ReviewDetails />} />
      <Route path="/manage-revenue" element={<Pages.ManageRevenue />} />
      <Route
        path="/manage-revenue/details"
        element={<Pages.RevenueDetails />}
      />

      <Route
        path="/manage-questionnaire"
        element={<Pages.ManageQuestionnaire />}
      />
      <Route
        path="/manage-questionnaire/add"
        element={<Pages.AddQuestionnaire />}
      />
      <Route path="/settings" element={<Pages.Settings />} />
      <Route path="/manage-AMLs" element={<Pages.ManageAMLs />} />
      <Route path="/manage-vendors" element={<Pages.ManageVendors />} />
      <Route path="/manage-vendors/add" element={<Pages.VendorForm />} />
      <Route path="/manage-vendors/add/:id/" element={<Pages.VendorForm />} />
      <Route path="/manage-vendors/edit" element={<Pages.VendorForm />} />
      <Route path="/manage-vendors/edit/:id/" element={<Pages.VendorForm />} />
      <Route
        path="/manage-vendors/details/:id"
        element={<Pages.VendorDetails />}
      />
    </Routes>
  );
};

export default Routing;
