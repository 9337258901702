import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isValidInput } from "../../utils/validations";

const ManageQuestionnaire = () => {
  const navigate = useNavigate();

  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Villas"), createData("Resorts")];
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Questionnaire</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
            <SearchBar value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }} />
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-questionnaire/add")}
                >
                  Add Questions
                </Button>
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    {/* <TableCell>Image</TableCell> */}
                    <TableCell>Questions</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
 
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-questionnaire/add")
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageQuestionnaire;
