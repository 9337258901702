import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  useAddUpdateAmlsMutation,
  useLazyGetAmlsQuery,
} from "../../services/aml";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const ManageAMLs = () => {
  const navigate=useNavigate();
  const [addUpdateAmls, { isLoading }] = useAddUpdateAmlsMutation();
  const [getAmls] = useLazyGetAmlsQuery();
  const [details, setDetails] = useState<any>();
  const userData=useAuth();
  console.log("02n23890n",userData);
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true
  });
  
  
  console.log("hide",hidePermission);

 
 
const permissions = userData?.role?.permissions; 
console.log("uhshqbsqy",permissions);
const permissions_name: string[] = [];  
 
if (permissions && permissions.length > 0) {
  permissions.forEach((permission: any) => {  
    const moduleName = permission?.module?.name; 
    permissions_name.push(moduleName);  
  });
}

console.log(permissions, "permissiame08"); 
console.log('osjss78282',userData);
const checkPermission = () => {
  let idx=-1;
  if (permissions_name?.length)
   {
   idx = permissions_name.findIndex(
      (moduleName: string) => moduleName === 'Manage AMLs'
    );

    console.log("In095fydex2897:", idx);

    if (idx !== -1) {
      const manageAmlPermission=permissions[idx];
      if(manageAmlPermission && !manageAmlPermission?.is_view)
      {
       showError("You dont't have the permission to view Manage Aml page");
        navigate("/dashboard",{replace:true});
      }
      let data = {
        is_add_edit: permissions[idx]?.is_add_edit,
        is_delete: permissions[idx]?.is_delete,
      };
      setHidePermission(data);
      console.log("lsksjdisjs",hidePermission);
    }
  }
};
 
useEffect(() => {
  if (userData && userData?.role?.permissions?.length) {
    checkPermission();
  }
}, [ userData]);
  const formik = useFormik({
    initialValues: {
      transaction_limit: "",
      transaction_amount_limit: "",
    },
    validationSchema: Yup.object({
      transaction_limit: Yup.string().required("Transaction limit is required"),
      transaction_amount_limit: Yup.string().required("Transaction amount limit is required"),
    }),
    

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        transaction_limit: Number(formik.values.transaction_limit),
        transaction_amount_limit: Number(
          formik.values.transaction_amount_limit
        ),
      };

      try {
        const response = await addUpdateAmls(body).unwrap();
        if (response?.code === 200) {
          
          showToast("Saved Successfully.");
        }
      } catch (error: any) {
        console.error("this is an error");
      }
    },
  });

  const getAllAml = async () => {
    try {
      const response = await getAmls({}).unwrap();
      formik.setValues({
        transaction_limit: response.data.transaction_limit.toString(),
        transaction_amount_limit:
          response.data.transaction_amount_limit.toString(),
      });
      setDetails(response?.data);
      console.log(response);
    } catch (error) {
      console.error("this is an error");
    }
  };

  useEffect(() => {
    getAllAml();
  }, []);

  
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage AMLs</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography
                    className="sub_headings"
                    sx={{ paddingBottom: 1 }}
                  >
                    Transactions limit per day
                  </Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="transaction_limit"
                    variant="outlined"
                    fullWidth
                    placeholder=" Transactions limit"
                    value={formik.values.transaction_limit}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const regex = /^\d{0,10}(?:\.\d{0,2})?$/;
                      if (regex.test(inputValue)) {
                        formik.handleChange(event);
                      }
                    }}                    
                    onBlur={formik.handleBlur}
                    helperText={
                      (formik.touched.transaction_limit &&formik.errors.transaction_limit) || ""
                    }
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography
                    className="sub_headings"
                    sx={{ paddingBottom: 1 }}
                  >
                    Transactions amount limit per day
                  </Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="transaction_amount_limit"
                    variant="outlined"
                    fullWidth
                    placeholder=" Amount limit"
                    value={formik.values.transaction_amount_limit}
                    // onChange={formik.handleChange}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const regex = /^\d{0,10}(?:\.\d{0,2})?$/;
                      if (regex.test(inputValue)) {
                        formik.handleChange(event);
                      }
                    }} 
                    onBlur={formik.handleBlur}
                    helperText={
                      (formik.touched.transaction_amount_limit &&
                        formik.errors.transaction_amount_limit) ||
                      ""
                    }
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
              {hidePermission?.is_add_edit ? ( 
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              ):null
}
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageAMLs;
