import React, { useState, useEffect } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import { useUpdateCmsMutation } from "../../services/manageCms";
import { useLazyGetAllCmsQuery } from "../../services/manageCms";
import { showError, showToast } from "../../constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Cms } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ManageCms = () => {
  const [privacy_policy, setprivacy_policy] = useState<string>("");
  console.log(typeof privacy_policy, "---privacy");
  const [legal, setLegal] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [about, setAbout] = useState<string>("");
  console.log("abbout", about);
  const [value, setValue] = React.useState(0);
  const [inputdata, setInputdata] = useState<any>();
  const [CountryCode, setCountryCode] = useState<string>("+91");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [country_code, setPhoneCode] = useState("+91");
  const [updateCms, { isLoading }] = useUpdateCmsMutation();
  const [getAllCms, {}] = useLazyGetAllCmsQuery();
  // const [details, setDetails] = useState<any>();
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true,
  });
  const permissions = userData?.role?.permissions;
  const permissions_name: string[] = [];

  if (permissions && permissions.length > 0) {
    permissions.forEach((permission: any) => {
      const moduleName = permission.module.name;
      permissions_name.push(moduleName);
    });
  }
  console.log(permissions_name, "981uwuhu2q");
  const checkPermission = () => {
    if (permissions_name?.length) {
      let idx = permissions_name.findIndex(
        (moduleName: string) => moduleName === "Manage CMS"
      );

      console.log("Index233093:", idx);

      if (idx !== -1) {
        const manageCmsPermission = permissions[idx];
        if (manageCmsPermission && !manageCmsPermission?.is_view) {
          showError("You dont't have the permission to view Manage Cms page");
          navigate("/dashboard", { replace: true });
        }
        let data = {
          is_add_edit: permissions[idx]?.is_add_edit,
          is_delete: permissions[idx]?.is_delete,
        };
        setHidePermission(data);
        console.log("lsksjdisjs", hidePermission);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      legal: String(inputdata?.termsAndConditions).trim() || "",
      email: inputdata?.email || "",
      phone_no: inputdata?.phone_no || "",
      privacy_policy: String(inputdata?.privacy_policy).trim() || "",
      country_code: "",
      about: String(inputdata?.about).trim() || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      phone_no: Yup.string().required("Phone is required"),
      privacy_policy: Yup.string().required().min(8, "Must be atleast 1 digit"),
    }),
    onSubmit: async (values) => {
      console.log("hello");

      formik.setSubmitting(true);
      let body = {
        legal: termsAndConditions,
        email: formik.values.email,
        phone_no: formik.values.phone_no,
        privacy_policy: privacy_policy,
        country_code: formik?.values?.country_code || CountryCode,
        about: about,
      };
      console.log("iweebebe", body);
      if (about === "<p></p>" || about === "<p></p>\n") {
        showError("please fill about");
        return;
      } else if (
        privacy_policy === "<p></p>" ||
        privacy_policy === "<p></p>\n" ||
        privacy_policy === "<p>&nbsp;&nbsp;</p>\n"
      ) {
        showError("please fill Privacy Policy");
        return;
      } else if (
        termsAndConditions === "<p></p>" ||
        termsAndConditions === "<p></p>\n"
      ) {
        showError("please fill Term & Conditions");
        return;
      } else {
        try {
          console.log("286723", body);
          const response = await updateCms(body).unwrap();
          if (response?.code === 200) {
            showToast("CMS updated successfully");
          }
        } catch (error: any) {
          // showError(error?.data?.message || "");
          console.error("this is an eror");
        }
      }
    },
  });
  // getall
  const getAll = async () => {
    try {
      const response = await getAllCms({}).unwrap();
      const cmsData = response?.data;
      formik.setValues({
        legal: cmsData?.legal || "",
        email: cmsData?.email || "",
        phone_no: cmsData?.phone_no || "",
        privacy_policy: cmsData?.privacy_policy || "",
        country_code: cmsData?.country_code || "",
        about: cmsData?.about || "",
      });
      setInputdata(cmsData);
      setprivacy_policy(cmsData?.privacy_policy || "");
      setTermsAndConditions(cmsData?.legal || "");
      setAbout(cmsData?.about || "");
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getAll();
  }, []);

  const handleChangePhone = (phone_no: any, country: any) => {
    formik.setFieldValue(
      "phone_no",
      phone_no.replace(country.country_code, "")
    );
    formik.setFieldValue("country_code", country.country_code); // Set country code in formik values
    setPhoneCode(country.country_code);
  };
  useEffect(() => {
    if (userData && userData?.role?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);
  // useEffect(() => {
  //   setCountryCode();
  // }, [])
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Privacy Policy" {...a11yProps(1)} />
              <Tab label="Term & Conditions" {...a11yProps(2)} />
              <Tab label="About" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      // disabled={!userId ? false : true}
                      hiddenLabel
                      type="email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      className="text_field"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      helperText={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email.toString()
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <div className="text_field">
                      <PhoneInput
                        country="us"
                        placeholder="0 (000) 000-000"
                        enableSearch={true}
                        inputStyle={{ width: "100%" }}
                        value={formik.values.phone_no}
                        onBlur={formik.handleBlur}
                        onChange={(phone_no, country) =>
                          handleChangePhone(phone_no, country)
                        }
                      />
                      {formik.touched.phone_no && formik.errors.phone_no ? (
                        <h6 className="err_msg">
                          {formik.touched.phone_no &&
                            formik.errors.phone_no.toString()}
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  {hidePermission?.is_add_edit ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacy_policy}
                  setContent={setprivacy_policy}
                />

                <div className="form_btn">
                  {hidePermission?.is_add_edit ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />

                <div className="form_btn">
                  {hidePermission?.is_add_edit ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            {/* about */}
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <EditText content={about} setContent={setAbout} />
                <div className="form_btn">
                  {hidePermission?.is_add_edit ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};
export default ManageCms;
