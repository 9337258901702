import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Loader, showError, showToast } from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  MenuItem,
  Typography,
  Tabs,
  Tab,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useLazyDeleteVendorQuery,
  useLazyPostgetVendorDetailsQuery,
  usePostChnageStatusMutation,
  useUpdateAccountStatusMutation,
} from "../../services/manageVendors";
import { isValidInput } from "../../utils/validations";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import { useUpdateVendorMutation } from "../../services/manageVendors";
import useAuth from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ManageVendors = () => {
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState<string>("1");
  const [open, setOpen] = useState(false);
  const [deleteVendor] = useLazyDeleteVendorQuery();
  const [accountStatus] = useUpdateAccountStatusMutation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [changeUserRequest] = usePostChnageStatusMutation();
  const [rows, setRows] = useState<any>([]);
  const [manage_user, setManageUser] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [vendorCurrStatus, { isLoading }] = useLazyPostgetVendorDetailsQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedId, setSelectedId] = useState<number>(0);
  const { id } = useParams();
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true,
  });

  // const [isLoading, setIsLoading] = useState(false);
  console.log("hide", hidePermission);

  // const updateUserStatus = async (id: number, status: boolean) => {
  //   const body = {
  //     is_active: status,
  //   };

  //   const response = await userStatus({
  //     body,
  //     userId: id,
  //   }).unwrap();
  //   if (response?.code === 200) {
  //     const updatedData = rows.map((x: any) => {
  //       if (x.id === id) {
  //         return {
  //           ...x,
  //           is_active: status,
  //         };
  //       }
  //       return x;
  //     });
  //     setManageUser(updatedData);
  //     showToast("Status updated successfully");
  //   } else {
  //     showError("Please try again");
  //   }
  // };

  const permissions = userData?.role?.permissions;
  console.log("uhshqbsqy", permissions);
  const permissions_name: string[] = [];

  if (permissions && permissions.length > 0) {
    permissions.forEach((permission: any) => {
      const moduleName = permission.module.name;
      permissions_name.push(moduleName);
    });
  }

  console.log(permissions_name, "permissions_name2");

  const checkPermission = () => {
    if (permissions_name?.length) {
      let idx = permissions_name.findIndex(
        (moduleName: string) => moduleName === "Manage vendors"
      );

      console.log("Index2897:", idx);

      if (idx !== -1) {
        const manageVendorsPermission = permissions[idx];
        console.log("02993jhehds", manageVendorsPermission);
        if (manageVendorsPermission && !manageVendorsPermission?.is_view) {
          showToast("You don't have the permission to view this page");
          navigate("/dashboard", { replace: true });
        } else {
          let data = {
            is_add_edit: permissions[idx]?.is_add_edit,
            is_delete: permissions[idx]?.is_delete,
          };
          setHidePermission(data);
          console.log("lsksjdisjs", hidePermission);
        }
      }
    } else {
      navigate("/dashboard", { replace: true });
      showError("You don't have permission for this activity");
    }
  };

  useEffect(() => {
    if (userData && userData?.role?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleReceiverChange = async (event: SelectChangeEvent, id: number) => {
    const body = {
      verification_status: Number(event.target.value),
    };
    try {
      const response = await changeUserRequest({ id, body }).unwrap();
      if (response?.code === 200) {
        showToast("Request changed successfully");
        current_state_of_users();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };

  const updateAccountStatus = async (id: number, status: boolean) => {
    const body = {
      is_active: status,
    };

    const response = await accountStatus({
      body,
      id: id,
    }).unwrap();
    if (response?.code === 200) {
      const updateAccount = rows.map((x: any) => {
        if (x.id === id) {
          return {
            ...x,
            is_active: status,
          };
        }
        return x;
      });
      setRows(updateAccount);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  const current_state_of_users = async () => {
    let body = {
      verification_status: value + 1,
      length: 10,
      start: page,
      search: debouncedSearchTerm.trim(),
    };
    try {
      setLoading(true);
      const response = await vendorCurrStatus(body).unwrap();
      if (response.code === 200) {
        setLoading(false);
        setTotalCount(response?.total_count);
        setRows(response?.data || []);
      } else {
        setLoading(false);
        setManageUser([]);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const handleDeleteVendor = async (id: number) => {
    try {
      const res = await deleteVendor({ id }).unwrap();
      if (res?.code === 200) {
        showToast("Vendor deleted successfully");
        await current_state_of_users();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };

  React.useEffect(() => {
    current_state_of_users();
  }, [value, page, debouncedSearchTerm]);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Vendors</h1>
        </div>

        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="Approved" {...a11yProps(1)} />
              <Tab label="Rejected" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />

                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-vendors/add")}
                  >
                    Add Vendor
                  </Button>
                  <Button className="btn btn_primary">
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Phone No.</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Account Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row: any, i: any) => (
                      <TableRow key={row.name}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          {`${row.first_name || "-"} ${row.last_name || ""}`}
                        </TableCell>
                        <TableCell>{`${row.country_code || ""} ${
                          row.phone_no || "-"
                        }`}</TableCell>
                        <TableCell>{row.email || "-"}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={receiver}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) => {
                              handleReceiverChange(value, row?.id);
                            }}
                          >
                            <MenuItem value="1" disabled>
                              Pending{" "}
                            </MenuItem>
                            <MenuItem value="2">Accept</MenuItem>
                            <MenuItem value="3">Reject</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={row?.is_active}
                            onChange={() =>
                              updateAccountStatus(row?.id, !row?.is_active)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-vendors/details/${row.id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>

                            {/* <IconButton
                              onClick={() => navigate(`/manage-vendors/edit/${row.id}`)}
                            >
                              <ModeEditIcon />
                            </IconButton> */}
                            {hidePermission?.is_delete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />

                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-vendors/edit")}
                  >
                    Add Vendor
                  </Button>
                  <Button className="btn btn_primary">
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Phone No.</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Account Status</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row: any, i: any) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          {`${row.first_name || "-"} ${row.last_name || ""}`}
                        </TableCell>
                        <TableCell>{`${row.country_code || ""} ${
                          row.phone_no || "-"
                        }`}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              row?.verification_status === 1
                                ? "1"
                                : row?.verification_status === 2
                                  ? "2"
                                  : "3"
                            }
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) =>
                              handleReceiverChange(value, row?.id)
                            }
                          >
                            <MenuItem value="1" disabled>
                              Pending
                            </MenuItem>
                            <MenuItem value="2">
                              {row?.verification_status === 2
                                ? "Accepted"
                                : "Accept"}
                            </MenuItem>
                            <MenuItem value="3">
                              {row?.verification_status === 3
                                ? "Rejected"
                                : "Reject"}
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={row?.is_active}
                            onChange={() =>
                              updateAccountStatus(row?.id, !row?.is_active)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-vendors/details/${row.id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {/* <IconButton
                              onClick={() => navigate(`/manage-vendors/edit/${row.id}`)}                            >
                              <ModeEditIcon />
                            </IconButton> */}
                            {hidePermission?.is_delete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />

                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-vendors/edit")}
                  >
                    Add Vendor
                  </Button>
                  <Button className="btn btn_primary">
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Phone No.</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Account Status</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row: any, i: any) => (
                      <TableRow key={row.name}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          {`${row.first_name || "-"} ${row.last_name || ""}`}
                        </TableCell>
                        <TableCell>{`${row.country_code || ""} ${
                          row.phone_no || "-"
                        }`}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              row?.verification_status === 1
                                ? "1"
                                : row?.verification_status === 2
                                  ? "2"
                                  : "3"
                            }
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) =>
                              handleReceiverChange(value, row?.id)
                            }
                          >
                            <MenuItem value="1" disabled>
                              Pending
                            </MenuItem>
                            <MenuItem value="2">
                              {row?.verification_status === 2
                                ? "Accepted"
                                : "Accept"}
                            </MenuItem>
                            <MenuItem value="3">
                              {row?.verification_status === 3
                                ? "Rejected"
                                : "Reject"}
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={row?.is_active}
                            onChange={() =>
                              updateAccountStatus(row?.id, !row?.is_active)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-vendors/details/${row.id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {/* Other action buttons */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
        {rows?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={rows}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteVendor(selectedId)}
        name="Vendor"
      />
    </MainContainer>
  );
};

export default ManageVendors;
