import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";

const Payments = () => {
  const navigate = useNavigate();

  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "a@yopmail.com",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];

  return (
    <div>
      <Box className="cards_header"></Box>
      <TableContainer className="table_container">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>

              <TableCell align="center">Sender Name</TableCell>
              <TableCell align="center">Sender Email</TableCell>
              <TableCell align="center">Payment Amount</TableCell>
              <TableCell align="center">Receiver Name</TableCell>
              <TableCell align="center">Commission</TableCell>
              <TableCell align="center">Date</TableCell>

              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>

              {/* <TableCell align="center">Actions</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={row.name}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.phone}</TableCell>
                <TableCell align="center">$100</TableCell>
                <TableCell align="center">John</TableCell>
                <TableCell align="center">$ 80</TableCell>
                <TableCell align="center">25/12/2024</TableCell>

                <TableCell align="center">Success</TableCell>
                {/* <TableCell>
                  <Switch {...label} defaultChecked size="small" />
                </TableCell> */}
                <TableCell>
                  <Box className="table_actions">
                    <IconButton
                      onClick={() => navigate("/manage-revenue/details")}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    {/* <IconButton
                      onClick={() => navigate("/manage-service-provider/edit")}
                    >
                      <ModeEditIcon />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon />
                    </IconButton> */}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Payments;
