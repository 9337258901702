import { END_POINTS } from "../constants/url";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
  [x: string]: any;
  total_count: number; 
  message: string;
  code: number;
};
type Document = {
  id: number;
  front_side: any;
  back_side: any;
  document_number: number;
  expiry_date: string;
};

type AdminAddVendorsPayload = {
  first_name: string;
  last_name: string;
  phone_no: string;
  email: string;
  profile_picture: any;
  vendor_category: number;
  documents: Document[];
};

type user_current_state = {
  verification_status: number,
  length: number,
  start: number,
  search: string
};
type PutBlockUserParams = {
  id: number;
  body: {
    is_active: boolean;
  };
};
type active_status = {
  body: { is_active: boolean };
  userId?: number;
};
type UserStatusParams = {
  body: {
    verification_status: number;
  };
  id: number;
};
type deleteVendor = {
  data: AdminAddVendorsPayload
};
type getvendorById = {
  data: AdminAddVendorsPayload
}
type PutUpdateParam = {
  id: number | string;
  body: AdminAddVendorsPayload
}
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // Management POST
    postAdminAddVendors: builder.mutation<
      CommonResponseType,
      AdminAddVendorsPayload
    >({
      query: (body) => ({
        url: END_POINTS.admin_add_vendors,
        method: "POST",
        body,
      }),
    }),

    //get all vendors
    PostgetVendorDetails: builder.query<CommonResponseType & { data: AdminAddVendorsPayload }, user_current_state>
      ({
        query: (body) => ({
          url: `${END_POINTS.all_vendors}`,
          method: "POST",
          body,
        }),
      }),
     //update a vendor by id
     updateVendor: builder.mutation<CommonResponseType, PutUpdateParam
     >({
       query: ({ body, id }) => ({
         url: `${END_POINTS.update_vendor}/${id}/`,
         method: "PUT",
         body
       })
     }),
    //delete a vendor
    deleteVendor: builder.query<
      CommonResponseType & AdminAddVendorsPayload,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.delete_vendor}/${id}/`,
        method: "DELETE",
      }),
    }),

    //update account status
    updateAccountStatus: builder.mutation<
      { message: string; code: number },
      PutBlockUserParams
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.update_vendor}/${id}/`,
        method: "PUT",
        body,
      }),
    }),

    //get a vendor by id
    getvendorById: builder.query<CommonResponseType & { data: any }, { userId: any}>
      ({
        query: ({ userId }) => ({
          url: `${END_POINTS.get_vendor_by_id}${userId}/`,
          method: "GET"
        })
      }),

    

    PostchangeVnedorStatus: builder.mutation<CommonResponseType, active_status>({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.active_status}${userId}/`,
        method: "POST",
        body,
      }),
    }),
    //update verification status
    PostChnageStatus: builder.mutation<CommonResponseType, UserStatusParams>({
      query: ({ id, body }) => ({
        url: `${END_POINTS.changeRequest}${id}/`,
        method: "POST",
        body
      })
    })
  }),

});

export const {
  usePostAdminAddVendorsMutation,
  useLazyPostgetVendorDetailsQuery,
  useLazyDeleteVendorQuery,
  useLazyGetvendorByIdQuery,
  useUpdateVendorMutation,
  useUpdateAccountStatusMutation,
  usePostChnageStatusMutation,
  usePostchangeVnedorStatusMutation

} = authApi;
