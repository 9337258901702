import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLazyGetUserByIdQuery } from "../../services/users";
import { ByIdUserDetail } from "../../types/General";
import { showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
const Details = () => {
  const { id } = useParams();
  const [userById] = useLazyGetUserByIdQuery();
  const [UserDetails, setUserDetails] = useState<ByIdUserDetail>();
  const userData = useAuth();
  const navigate = useNavigate();
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  console.log("hide", hidePermission);

   
  const permissions = userData?.role?.permissions;
  console.log("uhshqbsqy", permissions);
  const permissions_name: string[] = [];

  if (permissions && permissions.length > 0) {
    permissions.forEach((permission: any) => {
      const moduleName = permission.module.name;
      permissions_name.push(moduleName);
    });
  }

  console.log(permissions_name, "permissions_name2");


  const checkPermission = () => {
    if (permissions_name?.length) {
      let idx = permissions_name.findIndex(
        (moduleName: string) => moduleName === "Manage Users"
      );

      console.log("Index2333:", idx);

      if (idx !== -1) {
        const manageUserPermission=permissions[idx];
        if(manageUserPermission && !manageUserPermission?.is_view)
        {
          showError("You don't have the permission to view this page");
          navigate("/manageusers",{replace:true});
        }
 
        let data = {
          is_add_edit: permissions[idx]?.is_add_edit,
          is_delete: permissions[idx]?.is_delete,
        };
        setHidePermission(data);
        console.log("lsksjdisjs", hidePermission);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.role?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);
  const fetchById = async (id: any) => {
    console.log("-------get by id-----iiiiiiiiiiiiiiid", id);
    try {
      const response = await userById({ userId: id }).unwrap();
      if (response?.code === 200) {
        setUserDetails(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    fetchById(id);
  }, []);

  return (
    <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img src="/static/images/user_placeholder.png" alt="" />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Name</Typography>
                  <Typography component="p">{UserDetails?.name}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Username</Typography>
                  <Typography component="p">{UserDetails?.username}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p">{UserDetails?.phone_no}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p">{UserDetails?.email}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Account Status</Typography>
                  <Typography component="p">
                    {UserDetails?.is_active ? "Active" : "Inactive"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Wallet Balance</Typography>
                  <Typography component="p">$ 20,000</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">QR code</Typography>
                  <img
                    src="/static/images/qr.png"
                    style={{ width: "150px", height: "150px" }}
                  />
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>

                  <Box>
                    <Typography component="h5">
                      Document (front image)
                    </Typography>
                    <Card sx={{ p: 2 }}>
                      <figure style={{ paddingTop: "10px", margin: "0px" }}>
                        <img
                          src={
                            UserDetails?.document[0]?.front_side
                              ?.media_file_url || "/static/images/govId.png"
                          }
                          style={{ width: "300px", height: "200px" }}
                        />
                      </figure>
                      <Typography component="h5">
                        Document ID :{" "}
                        {UserDetails?.document[0]?.document_number}
                      </Typography>
                    </Card>
                  </Box>
                  <Box>
                    <Typography component="h5">
                      Document (back image)
                    </Typography>
                    <Card sx={{ p: 2 }}>
                      <figure style={{ paddingTop: "10px", margin: "0px" }}>
                        <img
                          src={
                            UserDetails?.document[0]?.back_side
                              ?.media_file_url || "/static/images/govId.png"
                          }
                          style={{ width: "300px", height: "200px" }}
                        />
                      </figure>
                      <Typography component="h5">
                        Document ID :{" "}
                        {UserDetails?.document[0]?.document_number}
                      </Typography>
                    </Card>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Details;
