import { END_POINTS } from "../constants/url";
import { AllFaq, Faqs } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  code: number;
  message: string;
};

type postAddFaq = {
  question: string;
  answer: string;
};

type getAllFaq = {
  data: AllFaq;
};

type deleteFaq = {
  data: string;
};

export const Managefaq = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addFaq: builder.mutation<
      CommonResponseType & { data: postAddFaq },
      postAddFaq
    >({
      query: (body) => ({
        url: END_POINTS.add_faqs,
        method: "POST",
        body,
      }),
    }),

    getAllFaq: builder.query<CommonResponseType & { data: getAllFaq }, {}>({
      query: () => ({
        url: END_POINTS.all_faqs,
        method: "GET",
      }),
    }),

    deleteFaq: builder.query<
      CommonResponseType & { data: Faqs },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.delete_faq}/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddFaqMutation,
  useLazyDeleteFaqQuery,
  useLazyGetAllFaqQuery,
} = Managefaq;
