import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/store";
import {
  usePostOtpMutation,
  usePostForgotPasswordMutation,
} from "../../../services/auth";
import { temporaryToken } from "../../../reducers/authSlice";
import {
  Loader,
  setToStorage,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../../constants";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import OtpInput from "react-otp-input";
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  FormControl,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const otpStyle: React.CSSProperties = {
  width: "50px",
  height: "50px",
  boxSizing: "border-box",
  margin: "0 auto",
};

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const dispatch = useAppDispatch();
  const [verifyOptMutation] = usePostOtpMutation();
  const [forgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();
  const [countDown, setCountDown] = useState<number>(59);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission
    console.log(typeof otp);
    setLoading(true);
    if (otp?.length === 4) {
      setError(false);
      const data = {
        email: state?.email,
        otp: String(otp),
      };

      try {
        const response: any = await verifyOptMutation(data).unwrap();
        if (response?.statusCode === 200 || response?.code === 200) {
          setLoading(false);
          // setToStorage(STORAGE_KEYS.tempToken, response?.data?.token);
          // dispatch(temporaryToken({ tempToken: response?.data?.token }));
          navigate("/resetpassword", { state: state, replace: true });
        }
      } catch (error: any) {
        if (error?.data?.message) {
          setLoading(false);
          showError(error?.data?.message || "");
        }
      }
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    let data = {
      email: state?.email,
      // otp:otp
    };
    try {
      const response = await forgotPasswordMutation(data).unwrap();
      if (response?.statusCode === 200 || response?.code === 200) {
        setLoading(false);
        showToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/forgotpassword")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Verify Otp
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Verify Otp
              </Button>
              {countDown === 0 ? (
                <Typography
                  className="anchor_link"
                  sx={{
                    cursor: "pointer",
                    textAlign: "center",
                    paddingTop: "10px",
                  }}
                  onClick={handleResendOtp}
                >
                  Resend Otp
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "grey",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  The verification code will expire in{" "}
                  <p style={{ margin: 0, color: "#D63853", paddingLeft: 3 }}>
                    00 : {countDown < 10 ? `0${countDown}` : countDown}
                  </p>
                </Typography>
              )}
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default VerifyOtp;
