import React, { useState, useEffect } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { isValidInput } from "../../utils/validations";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { AllUser, CommonBody, manage_User } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import {
  useLazyGetUserByIdQuery,
  useDeleteUser_by_adminMutation,
  useLazyPostgetAllUsersQuery,
  usePostChangeRequestMutation,
  usePostchangeUserStatusMutation,
} from "../../services/users";
import { Loader, showError, showToast } from "../../constants";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageUsers = () => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [user_curr_status] = useLazyPostgetAllUsersQuery();
  const [changeUserRequest] = usePostChangeRequestMutation();
  const [receiver, setReceiver] = useState<string>("1");
  const userData = useAuth();
  console.log("ppppou", userData);
  //const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [manageUser, setManageUser] = useState<manage_User[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [deleteUser] = useDeleteUser_by_adminMutation();
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true,
  });
  const [userStatus] = usePostchangeUserStatusMutation();
  console.log("hide", hidePermission);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const onPageChange = async (newPage: number) => {
    setPage(newPage);
    setIsLoading(true);
    try {
      await current_state_of_users(); // Fetch new data
    } catch (error: any) {
      showError(error?.data?.message || "");
    } finally {
      setIsLoading(false);
    }
  };

  console.log("owjmsns", page);

  let totalPages = Math.ceil(totalCount / 10);

  const permissions = userData?.role?.permissions;
  const permissions_name: string[] = [];

  if (permissions && permissions.length > 0) {
    permissions.forEach((permission: any) => {
      const moduleName = permission.module.name;
      permissions_name.push(moduleName);
    });
  }

  console.log(permissions_name, "permissions_name");

  const checkPermission = () => {
    if (permissions_name?.length) {
      let idx = permissions_name.findIndex(
        (moduleName: string) => moduleName === "Manage Users"
      );

      console.log("Index2333:", idx);

      if (idx !== -1) {
        const manageUserPermission = permissions[idx];
        if (manageUserPermission && !manageUserPermission?.is_view) {
          showError("You dont't have the permission to view User-Details page");
          navigate("/dashboard", { replace: true });
        }

        let data = {
          is_add_edit: permissions[idx]?.is_add_edit,
          is_delete: permissions[idx]?.is_delete,
        };
        setHidePermission(data);
        console.log("lsksjdisjs", hidePermission);
      }
    }
  };
  const updateUserStatus = async (id: number, status: boolean) => {
    const body = {
      is_active: status,
    };

    const response = await userStatus({
      body,
      userId: id,
    }).unwrap();
    if (response?.code === 200) {
      const updatedData = manageUser.map((x: any) => {
        if (x.id === id) {
          return {
            ...x,
            is_active: status,
          };
        }
        return x;
      });
      setManageUser(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  useEffect(() => {
    if (userData && userData?.role?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  //////
  const handleRequestStatus = async (event: SelectChangeEvent, id: number) => {
    const body = {
      verification_status: Number(event.target.value),
    };

    try {
      const response = await changeUserRequest({ id, body }).unwrap();
      if (response?.code === 200) {
        showToast(response?.message);
        current_state_of_users();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const current_state_of_users = async () => {
    let body = {
      verification_status: value + 1,
      length: 10,
      start: page,
      search: debouncedSearchTerm,
    };
    try {
      setLoading(true);
      const response = await user_curr_status(body).unwrap();
      console.log("lksskskwnwn", response);
      if (response.code === 200) {
        setLoading(false);
        setManageUser(response?.data || []);
        setTotalCount(response?.total_records);
      } else {
        setManageUser([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    current_state_of_users();
  }, [value, page, debouncedSearchTerm]);

  const handleDelete = async (id: number) => {
    try {
      const response = await deleteUser({ userId: id }).unwrap();
      if (response?.code === 200) {
        showToast("User deleted successfully");
        current_state_of_users();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  ///////
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
    setManageUser([]);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleSearch = async (searchTerm: string) => {
    setIsLoading(true);
    setDebouncedSearchTerm(searchTerm);

    try {
      await current_state_of_users();
    } catch (error: any) {
      showError(error?.data?.message || "");
    } finally {
      setIsLoading(false); // Set loading to false once search is complete
    }
  };
  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Users</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="Approved" {...a11yProps(1)} />
              <Tab label="Rejected" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              {" "}
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                  <Button className="btn btn_primary">
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Phone No.</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Account Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {manageUser.map((row, i: any) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>{row.name || "-"}</TableCell>
                        <TableCell>{row.username || "-"}</TableCell>

                        <TableCell>
                          {`${row?.country_code || ""} ${row.phone_no || "-"}`}
                        </TableCell>
                        <TableCell>{row.email || "-"}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              row?.verification_status === 1
                                ? "1"
                                : row?.verification_status === 2
                                  ? "2"
                                  : "3"
                            }
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) =>
                              handleRequestStatus(value, row?.id)
                            }
                          >
                            <MenuItem value="1" disabled>
                              Pending
                            </MenuItem>
                            <MenuItem value="2">Accept</MenuItem>
                            <MenuItem value="3">Reject</MenuItem>
                          </Select>
                        </TableCell>

                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={row?.is_active}
                            onChange={() =>
                              updateUserStatus(row?.id, !row?.is_active)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manageusers/userdetails/${row?.id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>

                            {/* {hidePermission?.is_add_edit ? ( 
                            <IconButton
                              onClick={() => navigate("/manageusers/usersform")}
                            >
                              <ModeEditIcon />
                            </IconButton>
                            ):null} */}
                            {hidePermission?.is_delete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?.id);
                                  // handleDelete(row?.id)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                  <Button className="btn btn_primary">
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Phone No.</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Account Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {manageUser.map((row, i) => (
                      <TableRow key={row.id || "-"}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.name || "-"} </TableCell>
                        <TableCell>{row.username || "-"}</TableCell>

                        <TableCell>{row.phone_no || "-"}</TableCell>
                        <TableCell>{row.email || "-"}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              row?.verification_status === 1
                                ? "1"
                                : row?.verification_status === 2
                                  ? "2"
                                  : "3"
                            }
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) =>
                              handleRequestStatus(value, row?.id)
                            }
                          >
                            <MenuItem value="1" disabled>
                              Pending
                            </MenuItem>
                            <MenuItem value="2">
                              {row?.verification_status === 2
                                ? "Accepted"
                                : "Accept"}
                            </MenuItem>
                            <MenuItem value="3">
                              {row?.verification_status === 3
                                ? "Rejected"
                                : "Reject"}
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={row?.is_active}
                            onChange={() =>
                              updateUserStatus(row?.id, !row?.is_active)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manageusers/userdetails/${row?.id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {/* {hidePermission?.is_add_edit ? ( 
                            <IconButton onClick={() => navigate(`/manageusers/usersform/${row?.id}`)}>
                              <ModeEditIcon />
                           </IconButton>
                            ):null} */}
                            {hidePermission?.is_delete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?.id);
                                  // handleDelete(row?.id)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {" "}
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                  <Button className="btn btn_primary">
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Phone No.</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Request</TableCell>
                      <TableCell>Account Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {manageUser.map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>{row.name || "-"}</TableCell>
                        <TableCell>{row.username || "-"}</TableCell>
                        <TableCell>{row.phone_no || "-"}</TableCell>
                        <TableCell>{row.email || "-"}</TableCell>
                        <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              row?.verification_status === 1
                                ? "1"
                                : row?.verification_status === 2
                                  ? "2"
                                  : "3"
                            }
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) =>
                              handleRequestStatus(value, row?.id)
                            }
                          >
                            <MenuItem value="1" disabled>
                              Pending
                            </MenuItem>
                            <MenuItem value="2">
                              {row?.verification_status === 2
                                ? "Accepted"
                                : "Accept"}
                            </MenuItem>
                            <MenuItem value="3">
                              {row?.verification_status === 3
                                ? "Rejected"
                                : "Reject"}
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={row?.is_active}
                            onChange={() =>
                              updateUserStatus(row?.id, !row?.is_active)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manageusers/userdetails/${row?.id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {/* {hidePermission?.is_add_edit ? ( 
                            <IconButton onClick={() => navigate(`/manageusers/usersform/${row?.id}`)}>
                              <ModeEditIcon />
                           </IconButton>
                            ):null} */}

                            {hidePermission?.is_delete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>

        {manageUser?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={manageUser}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>

      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="User"
      />
    </MainContainer>
  );
};

export default ManageUsers;
