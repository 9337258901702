import { END_POINTS } from "../constants/url";
import {   SubAdmin, SubAdminBody, SubAdminPagination, SubAdminUpdateBody } from "../types/General";
import { Commision } from "../types/User";
import emptySplitApi from "../utils/rtk";
// import { UpdateUser } from "../types/General";
type CommonResponseType = {
  data:{
    length: number,
    start: number,
    search: any
  }
  code: number;
  message: string;
  total_count?: any;
};
type CommisionBody={
    commision:number
}
type UpdateUser={
    body:{
        first_name:string,
        user_name:string,
        last_name:string,
        image:number,
        age:number,
        zipcode:string,
        address:  string,
        gender:string,
        email:string,
        phone_no:string,
        password:string,
        user_role:number,
        country_code:string
    }
    userId:any
}
type pagination={
  length:number,
  start:number,
  search:string
}
type active_status = {
  body: { is_active: boolean };
  userId?: number;
};
export const settingsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    PostSubAdminPage: builder.query<CommonResponseType ,pagination>
    ({
      query: (body ) => ({
        url: `${END_POINTS.SubAdminMainPage}`,
        method: "POST",
        body
      }),
    }),
    
    GetSubAdminById: builder.query<CommonResponseType & { data: any }, {userId:any}>({
        query: ({userId}) => ({
          url: `${END_POINTS.getSubAdminById}${userId}/`,
          method: "GET",
        }),
      }),
        GetModule:builder.query<CommonResponseType , {}>({
          query: () => ({
            url: `${END_POINTS.module}`,
            method: "GET",
          }),
        }),
  
      PutUpdateSubAdminById: builder.query<CommonResponseType & { data: any }, UpdateUser>({
        query: ({userId,body}) => ({
            url: `${END_POINTS.UpdateSubAdminById}${userId}/`,
            method: "PUT",
            body
        }),
    }),
     
    PostAddSubAudmin:builder.mutation<CommonResponseType,SubAdmin> 
    ({
      query:(body)=>({
        url: END_POINTS.AddSubAdmin,
        method: "POST",
        body,
      })
    }),
    PostchangeSubAdminStatus: builder.mutation<CommonResponseType, active_status>({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.active_status}${userId}/`,
        method: "POST",
        body,
      }),
    }),
    DeleteSubAdminById: builder.mutation<CommonResponseType & { data: any }, {userId:any}>({
      query: ({userId}) => ({
        url: `${END_POINTS.DeleteSubAdminById}${userId}/`,
        method: "DELETE",
      }),
    }),
    // updateSubAdminById
    byIdUpdate: builder.mutation<CommonResponseType & { data: any }, { userId: any, body:SubAdmin }>({
      query: ({ userId, body }) => ({
        url: `${END_POINTS.updateSubAdminById}${userId}/`,
        method: "PUT",
        body
      }),
    }),
    
    
    
    
  }),
});

export const {  
    useLazyGetSubAdminByIdQuery,
    useLazyPutUpdateSubAdminByIdQuery, 
    // usePostSubAdminListingMutation,
    usePostAddSubAudminMutation,
    useDeleteSubAdminByIdMutation,
    // usePostSubAdminMainPageMutation,
    useLazyPostSubAdminPageQuery,
    useLazyGetModuleQuery,
    useByIdUpdateMutation,
    usePostchangeSubAdminStatusMutation
 } = settingsApi;
