import { END_POINTS } from "../constants/url";
import { Commision } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
   code: number;
  message: string;
};
 
export const settingsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    GetDashBoardData: builder.query<CommonResponseType & { data: any },{}>({
      query: () => ({
        url: `${END_POINTS.DashBoardData}`,
        method: "GET",
      }),
    }), 
  }),
});

export const {  
    useLazyGetDashBoardDataQuery
 } = settingsApi;
