import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  useUpdateCommisionMutation,
  useLazyGetCommisionQuery,
} from "../../services/settings";
import { number } from "yup";
import { showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate=useNavigate();
  const [commissionFee, setCommissionFee] = useState("");
  const [updateCommision] = useUpdateCommisionMutation();
  const [commissionData] = useLazyGetCommisionQuery();
  const [error, setError] = useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    is_add_edit: true,
    is_view: true,
    is_delete: true,
  });

  console.log("hide", hidePermission);

  const permissions = userData?.role?.permissions;
  console.log("uhshqbsqy", permissions);
  const permissions_name: string[] = [];

  if (permissions && permissions.length > 0) {
    permissions.forEach((permission: any) => {
      const moduleName = permission?.module?.name;
      permissions_name.push(moduleName);
    });
  }

  console.log(permissions, "permissiame09098");
  console.log("osjss78282", userData);
  const checkPermission = () => {
    let idx = -1;
    if (permissions_name?.length) {
      idx = permissions_name.findIndex(
        (moduleName: string) => moduleName === "Settings"
      );

      console.log("In095fydex287:", idx);

    
        if (idx !== -1) {
          const manageSettings=permissions[idx];
          console.log(manageSettings,"---39xwds3839--");
          if(manageSettings && !manageSettings?.is_view)
          {
           showError("You dont't have the permission to view settings page");
            navigate("/dashboard",{replace:true});
          }
          
        let data = {
          is_add_edit: permissions[idx]?.is_add_edit,
          is_delete: permissions[idx]?.is_delete,
        };
        setHidePermission(data);
        console.log("lsksjdisjs", hidePermission);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.role?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);
  async function handleGetCom() {
    const res = await commissionData({}).unwrap();
    console.log("res=  ", res);
    try {
      if (res?.code === 200) {
        setCommissionFee(res?.data?.commision);
        formik.setFieldValue("commission", res?.data?.commision);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      commission: "",
    },

    validationSchema: Yup.object({
      commission: Yup.string()
        .required("Please enter service tax")
        // .max(500, "Maximum 500 character are allowed")
        .min(2, "Minimum 2 digits are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        commision: values.commission,
      };

      try {
        const response = await updateCommision(body).unwrap();
        if (response?.code === 200) {
          showToast("Settings updated successfully");
          handleGetCom();
          formik.resetForm();
        } else {
          showError(response?.message);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    handleGetCom();
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Settings</h1>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Card className="cards">
            <CardContent sx={{ p: 1 }}>
              <Typography className="sub_headings" sx={{ paddingBottom: 2 }}>
                Commission Fee
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="commission"
                    variant="outlined"
                    fullWidth
                    placeholder="Commission fee"
                    onChange={(val) => {
                      const inputValue = val.target.value;

                      // Check if the input is a valid number with up to 2 decimals and a maximum of 10 digits
                      const isValidInput = /^(\d{1,2}(\.\d{0,2})?)?$/.test(
                        inputValue
                      );

                      if (isValidInput) {
                        // Update the form field only if the input is valid
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.commission}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.commission && formik.errors.commission ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4} />
              </Grid>
              <div className="form_btn">
                {hidePermission?.is_add_edit ? (
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                ) : null}
              </div>
            </CardContent>
          </Card>
        </form>
      </div>
    </MainContainer>
  );
};

export default Settings;
