import { END_POINTS } from "../constants/url";
import { SubAdmin, SubAdminPagination, createRoll } from "../types/General";
import { Commision } from "../types/User";
import emptySplitApi from "../utils/rtk";
// import { UpdateUser } from "../types/General";
type CommonResponseType = {
  data?: any;
 
  code: number;
  message: string;
};
type CommisionBody={
    commision:number
}
type UpdateUser={
    body:{
        first_name:string,
        user_name:string,
        last_name:string,
        image:number,
        age:number,
        zipcode:string,
        address:  string,
        gender:string,
        email:string,
        phone_no:string,
        password:string,
        user_role:number,
        country_code:string
    }
    userId:any
}
type SubAdminPaging={
     
    length: number,
    start: number,
    search: string
  
      }
      type active_status = {
        body: { is_active: boolean };
        userId?: number;
      };
export const settingsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    
    GetSubAdminPagination: builder.query<CommonResponseType , {}>
    ({
      query: ( ) => ({
        url: `${END_POINTS.SubAdminListing}`,
        method: "GET",
        
      }),
    }),
  
    //   PutUpdateSubAdminById: builder.query<CommonResponseType & { data: any }, UpdateUser>({
    //     query: ({userId,body}) => ({
    //         url: `${END_POINTS.UpdateSubAdminById}${userId}/`,
    //         method: "PUT",
    //         body
    //     }),
    // }),
    // SubAdminListing
    // PostSubAdminListing:builder.mutation<CommonResponseType,SubAdminPagination> 
    // ({
    //   query:(body)=>({
    //     url: END_POINTS.SubAdminListing,
    //     method: "POST",
    //     body,
    //   })
    // }),
    PostSubAdminCreateRole:builder.mutation<CommonResponseType,createRoll> 
    ({
      query:(body)=>({
        url: END_POINTS.SubAdminCreateRoll,
        method: "POST",
        body,
      })
    }),
    //delete deleteSubAdmin
    deleteRoleSubAdminById: builder.mutation<CommonResponseType , {userId:number}>({
      query: ({userId}) => ({
        url: `${END_POINTS.deleteRoleSubAdmin}${userId}/`,
        method: "DELETE",
      }),
    }),
    PostchangeSubAdminRoleStatus: builder.mutation<CommonResponseType, active_status>({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.active_status}${userId}/`,
        method: "POST",
        body,
      }),
    }),
   
  }),
});

export const {  
  useDeleteRoleSubAdminByIdMutation,
    usePostSubAdminCreateRoleMutation,
    useLazyGetSubAdminPaginationQuery,
    usePostchangeSubAdminRoleStatusMutation
 } = settingsApi;
