import React, { PropsWithoutRef, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import LockResetIcon from "@mui/icons-material/LockReset";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  getFromStorage,
  removeFromStorage,
  SIDEBAR_WIDTH,
  STORAGE_KEYS,
} from "../constants";
import { useLazyGetAdminQuery } from "../services/auth";
import { useAppDispatch } from "../hooks/store";
import "./Layout.scss";
import useAuth from "../hooks/useAuth";
import { resetAuth, setCredentials } from "../reducers/authSlice";
import { generateResponsiveStyle } from "../utils/ModalStyle";

interface Props {
  handleSidebarToggle: () => void;
}

function Topbar({ handleSidebarToggle }: PropsWithoutRef<Props>) {
  const navigate = useNavigate();
  const user = useAuth();
  console.log("state topbar", user);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const [getUser, { isLoading }] = useLazyGetAdminQuery();

  const getLang = getFromStorage(STORAGE_KEYS.language);
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [lang, setLang] = React.useState<string>("");
  const style = generateResponsiveStyle();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );
  const open1 = Boolean(anchorEl1);
  const openLang = Boolean(anchorElLang);

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getUser({}).unwrap();
      console.log(result, "topbar response");
      if (result?.code === 200 && token) {
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);
    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: userData,
        })
      );
    }
    getUserDetails();
  }, []);
  //done
  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token) {
      dispatch(
        setCredentials({
          user: null,
          token: token,
        })
      );
    }
  }, []);
  //done

  //done
  const getLogoutUser = async () => {
    removeFromStorage(STORAGE_KEYS.token);
    dispatch(resetAuth());
    navigate("/", { replace: true });
  };
  const handleCloseLang = () => {
    setAnchorElLang(null);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Box className="container top_bar">
      <AppBar
        position="fixed"
        className="topbar"
        sx={{
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
          ml: { lg: `${SIDEBAR_WIDTH}px` },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleSidebarToggle}
            style={{ backgroundColor: "#72cedd" }}
            sx={{
              display: { xs: "flex", lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          {isLoading ? ( // Render CircularProgress if isLoading is true
            <CircularProgress color="inherit" size={24} />
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ mr: 2 }}>
                <IconButton
                  className="notification_btn"
                  aria-controls={open1 ? "basic-menu1" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={handleClick1}
                >
                  <NotificationsActiveIcon />
                </IconButton>
                <Menu
                  className="notiDropdown_menu"
                  id="basic-menu1"
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={handleClose1}
                  MenuListProps={{
                    "aria-labelledby": "basic-button1",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 20,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Box className="noti_head">
                    <Typography component="h2">Notifications</Typography>
                    <Button
                      className="btn btn_primary sm"
                      onClick={() => navigate("/recieved-notifications")}
                    >
                      See All
                    </Button>
                  </Box>
                  <ul className="noti_list">
                    <li>
                      <figure>
                        <img src="/static/images/user_placeholder.png" alt="" />
                      </figure>
                      <div className="noti_info">
                        <Typography component="h3">
                          JohnDoe
                          <Typography component="span">1 Day ago</Typography>
                        </Typography>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img src="/static/images/user_placeholder.png" alt="" />
                      </figure>
                      <div className="noti_info">
                        <Typography component="h3">
                          JohnDoe
                          <Typography component="span">1 Day ago</Typography>
                        </Typography>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                      </div>
                    </li>
                    <li>
                      <figure>
                        <img src="/static/images/user_placeholder.png" alt="" />
                      </figure>
                      <div className="noti_info">
                        <Typography component="h3">
                          JohnDoe
                          <Typography component="span">1 Day ago</Typography>
                        </Typography>
                        <Typography component="p">
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit dolor sit amet consectetur.
                        </Typography>
                      </div>
                    </li>
                  </ul>
                </Menu>
              </Box>
              <Box>
                <IconButton
                  className="topbar_user_btn"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img
                    style={{
                      marginRight: 8,
                    }}
                    src={
                      user?.profile_picture?.media_file_url ||
                      "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                  <span>{user?.first_name}</span>
                  <KeyboardArrowDownIcon />
                </IconButton>
                <Menu
                  className="userDropdown_menu"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 10,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    className="userDropdown_menu_list"
                    onClick={() => navigate("/profile")}
                  >
                    <IconButton className="drpdwn_icns">
                      <PersonIcon />
                    </IconButton>
                    My Profile
                  </MenuItem>
                  <MenuItem
                    className="userDropdown_menu_list"
                    onClick={() => navigate("/changepassword")}
                  >
                    <IconButton className="drpdwn_icns">
                      <LockResetIcon />
                    </IconButton>
                    Change Password
                  </MenuItem>
                  <MenuItem
                    className="userDropdown_menu_list"
                    onClick={() => setOpenModal(true)}
                  >
                    <IconButton className="drpdwn_icns">
                      <ExitToAppIcon />
                    </IconButton>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ position: "relative" }}>
            <div className="cross_icn_logout">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>

            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", fontSize: "19px" }}
            >
              Are you sure you want to logout ?
            </Typography>
            <div className="flexdiv">
              <Button
                sx={{ color: "#204e33", border: "1px solid #2593bd" }}
                className="yesBtn"
                onClick={() => {
                  setOpenModal(false);
                  getLogoutUser();
                }}
              >
                Yes
              </Button>

              <Button
                sx={{
                  color: "#2593bd",
                  border: "1px solid #2593bd",
                  marginLeft: 2,
                }}
                onClick={() => setOpenModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
}

export default Topbar;
